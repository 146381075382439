import React, { useState } from "react";
import Label from "../../../../../components/Label";
import Box from "../../../../../components/Box";
import Button from "../../../../../components/Buttons";
import { useDispatch } from "react-redux";
import { FaTimes } from "react-icons/fa";
import { updateMosque, updateMosqueAdminDetails } from "../../../adminAction";
import Icon from "../../../../../components/Icon";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { getFormattedTime } from "../../../../../util/timeUtility";
import Input from "../../../../../components/Input";
import isNumeric from "validator/es/lib/isNumeric";
import { useTranslation } from "react-i18next";
import { background } from "styled-system";
import { truncate } from "lodash";
import { showNotification } from "../../../../../util/Utility";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import axios from "axios";
import { useSelector } from "react-redux";

const EditMosque = ({ item, setSelectedMosque }) => {
  const dispatch = useDispatch();
  const [mosqueDetails, setMosqueDetails] = useState(
    JSON.parse(JSON.stringify(item))
  );
  const [isNext, setIsNext] = useState(false);
  const [errors, setErrors] = useState({});
  const [isShowDelete, setIsShowDelete] = useState(false);
  const [deletePdf, setDeletePdf] = useState(null)
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const { t } = useTranslation();

  const handleUpdate = (keyName, keyValue) => {
    setIsNext(true);
    let update = { ...mosqueDetails };
    if (keyName === "contact_name") {
      update["primary_user_id"]["contact_name"] = keyValue;
    } else {
      update[keyName] = keyValue;
    }
    setMosqueDetails(update);
  };

  const handleValidation = () => {
    let isValid = true;
    console.log(mosqueDetails);
    let errors = {};
    if (!mosqueDetails.name) {
      isValid = false;
      errors["name"] = "Please enter name";
    }
    if (
      !mosqueDetails.admin_fee ||
      !isNumeric(mosqueDetails.admin_fee.toString())
    ) {
      isValid = false;
      errors["admin_fee"] = "Please enter valid admin fee";
    }
    if (!mosqueDetails.entity_merchant_id) {
      isValid = false;
      errors["entity_merchant_id"] = "Please enter valid merchant id";
    }
    if (!mosqueDetails.entity_merchant_key) {
      isValid = false;
      errors["entity_merchant_key"] = "Please enter valid merchant key";
    }
    if (!mosqueDetails?.primary_user_id?.contact_name) {
      isValid = false;
      errors["contact_name"] = "Please enter admin name";
    }
    if (!mosqueDetails?.entity_merchant_id) {
      isValid = false;
      errors["entity_merchant_id"] = "Please enter merchant_id";
    }
    if (!mosqueDetails?.entity_merchant_key) {
      isValid = false;
      errors["entity_merchant_key"] = "Please enter merchant_key";
    }
    return {
      isValid,
      errors,
    };
  };

  const handleUpdateButton = () => {
    setErrors({});
    let validate = handleValidation();
    if (validate.isValid) {
      let details = { ...mosqueDetails };
      const mosque = {};
      mosque.name = details?.name.trim();
      mosque._id = details?._id;
      if (details?.admin_fee) {
        mosque.admin_fee = details?.admin_fee;
      }
      if (details?.entity_merchant_id) {
        mosque.entity_merchant_id = details?.entity_merchant_id;
      }
      if (details?.entity_merchant_key) {
        mosque.entity_merchant_key = details?.entity_merchant_key;
      }
      const admin = {};
      admin.contact_name = details?.primary_user_id?.contact_name.trim();
      admin._id = details?.primary_user_id?._id;
      if (mosque?._id && mosque) {
        dispatch(updateMosque(mosque?._id, mosque));
      }
      if (admin?._id && admin) {
        dispatch(updateMosqueAdminDetails(admin?._id, admin));
      }
    } else {
      setErrors(validate.errors);
    }
  };
  const getPdfName = (file) => {
    const decodedUrl = decodeURIComponent(file);
    const fileName = decodedUrl.substring(decodedUrl.lastIndexOf("-") + 1);
    return fileName;
  };

  const handleRemoveFile = async () => {
    try {
      
      const res = await axios.post(`${process.env.REACT_APP_API_URL}file/delete/autopay-pdf?entityId=${item?._id}`,{
        fileName:deletePdf
      })
      setIsShowDelete(false)
      showNotification('success','file deleted successfully..')
      window.location.reload()
      
    } catch (error) {
      console.log(error);
      setIsShowDelete(false)
      showNotification("error", "some error occurred .. ");
    }
  };

  return (
    <Box px="20px" py="28px">
      <Box display="flex">
        <Label
          fontSize="20px"
          fontWeight={700}
          cursor="pointer"
          onClick={() => setSelectedMosque(null)}
        >
          {t("Moskee informatie")}
        </Label>
        <Label fontSize="20px" fontWeight={700}>
          {" > "}
        </Label>
        <Label fontSize="20px" fontWeight={700} color="#7e7e7e">
          {t("Moskee bewerken")}
        </Label>
      </Box>
      <div className="file-list">
        {mosqueDetails?.approvalPdfs?.map((file, index) => {
          const fileName = getPdfName(file);
          return (
            <div
              key={index}
              onClick={() => window.open(`${file}`)}
              className="file-pill"
              style={{ cursor: "pointer" }}
            >
              <span className="file-name">
                {truncate(fileName, { length: 20 })}
              </span>
              <FaTimes
                className="remove-icon"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsShowDelete(!isShowDelete);
                  setDeletePdf(file)
                }}
              />
            </div>
          );
        })}
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          <label>
            {t("Naam Moskee")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            value={mosqueDetails.name}
            onChange={(e) => handleUpdate("name", e.target.value)}
            type="text"
            placeholder={t("Voer Naam Moskee In")}
            className="input-box"
          />
          {errors.name && (
            <ErrorMessage>{JSON.stringify(errors.name)}</ErrorMessage>
          )}
        </div>
        <div className="col-lg-6">
          <label>
            {t("Adres Moskee")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            disabled
            value={mosqueDetails.address}
            onChange={(e) => handleUpdate("address", e.target.value)}
            type="text"
            placeholder={t("Voer Adres Moskee In")}
            className="input-box"
          />
        </div>

        <div className="col-lg-6 pt-4">
          <label>
            {t("Postcode")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            disabled
            value={mosqueDetails.pincode}
            onChange={(e) => handleUpdate("pincode", e.target.value)}
            type="text"
            placeholder={t("Voer postcode in")}
            className="input-box"
          />
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t("Land Moskee")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            disabled
            value={mosqueDetails.country}
            type="text"
            placeholder="Enter country"
            className="input-box"
          />
        </div>
        <div className="col-lg-6 pt-4 customDatePicker">
          <label>
            {t("Lidmaatschapsdatum")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <br></br>
          <Input
            isDisabled
            value={getFormattedTime(
              mosqueDetails?.organisation_id?.membership_date ??
                mosqueDetails?.organisation_id?.created_at,
              "DD-MM-YYYY"
            )}
            id="memberDate"
            className="input-box"
          />
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t("Beheerdersnaam")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            value={mosqueDetails?.primary_user_id?.contact_name}
            onChange={(e) => handleUpdate("contact_name", e.target.value)}
            type="text"
            placeholder={t("Voer beheerdersnaam in")}
            className="input-box"
          />
          {errors.contact_name && (
            <ErrorMessage>{JSON.stringify(errors.contact_name)}</ErrorMessage>
          )}
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t("Beheerderskosten")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            value={mosqueDetails?.admin_fee}
            onChange={(e) => handleUpdate("admin_fee", e.target.value)}
            type="text"
            placeholder={t("Voer beheerderskosten in")}
            className="input-box"
          />
          {errors.admin_fee && (
            <ErrorMessage>{JSON.stringify(errors.admin_fee)}</ErrorMessage>
          )}
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t("Merchant-ID")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            value={mosqueDetails?.entity_merchant_id}
            onChange={(e) => handleUpdate("entity_merchant_id", e.target.value)}
            type="text"
            placeholder={t("Voer Merchant-ID in")}
            className="input-box"
          />
          {errors.entity_merchant_id && (
            <ErrorMessage>
              {JSON.stringify(errors.entity_merchant_id)}
            </ErrorMessage>
          )}
        </div>
        <div className="col-lg-6 pt-4">
          <label>
            {t("Merchant Key")}
            <span style={{ color: "#FE1717" }}>*</span>
          </label>
          <input
            value={mosqueDetails?.entity_merchant_key}
            onChange={(e) =>
              handleUpdate("entity_merchant_key", e.target.value)
            }
            type="text"
            placeholder={t("Voer Merchant Key in")}
            className="input-box"
          />
          {errors.entity_merchant_key && (
            <ErrorMessage>
              {JSON.stringify(errors.entity_merchant_key)}
            </ErrorMessage>
          )}
        </div>
      </div>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button
          text={t("Annuleren")}
          width="15%"
          mt="4"
          height="60px"
          onClick={() => setSelectedMosque(null)}
        />
        <Button
          text={t("Save Details")}
          width="15%"
          mt="4"
          ml="4"
          height="60px"
          onClick={() => handleUpdateButton()}
          type={!isNext ? "disabled" : "primary"}
        />
      </div>
      <DefaultModal
        show={isShowDelete}
        onHide={() => setIsShowDelete(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={true}
        showHeader={false}
        size="md"
        showFooter={false}
      >
        <div
          className="congrats-screen"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <div
            style={{ position: "absolute", right: 20 }}
            onClick={() => setIsShowDelete(false)}
          >
            <Icon iconName="blackCross" />
          </div>
          <Box mt="15px">
            <h4>{t("Delete PDF")}</h4>
          </Box>
          <Label color="#7E7E7E">{t("You will loose this PDF details.")}</Label>

          <div className="d-flex justify-content-around mt-3 gap-5">
            <button className="prev-bt">{t("Cancel")}</button>
            <button className="fill-bt" onClick={() => handleRemoveFile()}>
              {t("Delete PDF")}
            </button>
          </div>
        </div>
      </DefaultModal>
    </Box>
  );
};

export default EditMosque;
