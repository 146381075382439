import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setOnboardingDetails } from "../../../customerAction";
import Dropdown from "../../../../../components/Dropdown";
import { countryCodeList ,cityCodeList} from "../../../../../constants/countryConstants";
import { variant } from "styled-system";

const SelectBoxCountry = ({ selectedCountry, handleChangeCountry, handleChangeCity }) => {
  const dispatch = useDispatch();
  const [selectedCity, setSelectedCity] = useState("");
  const countries = useSelector((state) => state.mosqueReducer.countries);
  const cities = useSelector((state) => state.mosqueReducer.cities);
  const onboardingDetails = useSelector((state) => state.customerReducer.onboardingDetails)

  /*const handleChange = (value) => {
    setSelectedCountry(value);
    setSelectedCity("");
    dispatch(setOnboardingDetails("entity_country", value));
    const filteredData = countryCodeList.filter((item) => item.name === value)?.[0];
    if (filteredData?.code) {
      dispatch(setOnboardingDetails("country_code", filteredData?.code));
    }
  };*/

  const handleCountryChange = (value) => {
    handleChangeCountry(value);
    setSelectedCity(""); // Reset selected city when country changes
  };

  const handleCityChange = (value) => {
    setSelectedCity(value);
    handleChangeCity(value);
  };


  return (
    <>
     <div className="country-dropdown">
      <Dropdown
          allowSearch
          variant="outlinedGreen"
          defaultText="Selecteer Land"
          color="#1A1A1A"
          fontWeight="500"
          width="100%"
          height="54px"
          Icon="none"
          selectedValue={selectedCountry}          
          onOptionClick={(item) => handleCountryChange(item?.value)}
          options={countries}
      />
      </div>
      <div className="city-dropdown">
      <Dropdown
      className="custom-dropdown"
        allowSearch
        variant="outlinedGreen"
        defaultText="Selecteer stad"
        color="#1A1A1A"
        fontWeight="500"
        width="100%"
        height="54px"
        Icon="none"
        selectedValue={selectedCity}
        onOptionClick={(item) =>handleCityChange(item?.value)}
        options={cities}
        optionClassName="centered-option" 
      />
      </div>
    </>
  );
};

export default SelectBoxCountry;
