import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

const TabContainer = styled.div`
  display: flex;
  border: 0.5px solid #d0004b;
    border-radius: 50px;
    width: 450px;
    padding-left: 0px;
    height: 64px;
    list-style: none;
  // position: absolute;
  // right:35px
`;


const TabButton = styled.button`
  flex: 1;
  padding: 15px;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => (props.active ? '#fff' : '#d11244')};
  background-color: ${(props) => (props.active ? '#d11244' : '#fff')};
  transition: background-color 0.3s ease, color 0.3s ease;

  &:first-child {
    border-radius: 50px 0 0 50px;
  }

  &:last-child {
    border-radius: 0 50px 50px 0;
  }

  &:hover {
    background-color: ${(props) => (props.active ? '#c20e3e' : '#f5f5f5')};
  }
`;

const PAST_CAMPAIGNS = "pastCampaigns"
const ADD_CAMPAIGN = "addCampaign"



const DonateTabs = ({activeTab,setActiveTab, shouldReorder, setShouldReoder}) => {

  const {t} = useTranslation()

  const getLabel= ()=>{
    if(activeTab === PAST_CAMPAIGNS)
      return <span>{t('Fund Me')}
  </span>
    else if( activeTab === ADD_CAMPAIGN)
      return t("Fund Me")
   }

  return (
    <div style={{display:'flex', justifyContent:'space-between', alignItems:'center', marginBottom:'50px'}}>
      <h2 style={{fontSize:'22px', fontWeight:700}}>{getLabel()}</h2>
      <TabContainer>
      <TabButton
        active={activeTab === PAST_CAMPAIGNS}
        onClick={() => setActiveTab(PAST_CAMPAIGNS)}
      >
        {t('Past Campaigns')}
      </TabButton>
      <TabButton
        active={activeTab === ADD_CAMPAIGN}
        onClick={() => setActiveTab(ADD_CAMPAIGN)}
      >
        {t('Add Campaign')}
      </TabButton>
    </TabContainer>
    </div>
  );
};

export default DonateTabs;
