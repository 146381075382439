import React, { useEffect, useState } from "react";
import "./CampignCard.css";
import Icon from "../../../../../components/Icon";
import moment from "moment";
import { truncate } from "lodash";
import { showNotification } from "../../../../../util/Utility";
import axios from "axios";
import { useTranslation } from "react-i18next";

const PAST_CAMPAIGNS = "pastCampaigns";
const ADD_CAMPAIGN = "addCampaign";

const CampaignCard = ({ data, shouldReorder, setClickedCard, setShowTrackModal }) => {
  const [isActive, setIsActive] = useState(false)
  const {t} = useTranslation();
  const handleSwitch = async(e)=>{
   e.stopPropagation()
   try {
     const newActiveState = !isActive;
     const update = {
      is_active:newActiveState
     }
     const res = await axios.post(`${process.env.REACT_APP_API_URL}donation/edit-campaign?campaignId=${data._id}`,update)
     console.log(res);
     setIsActive(newActiveState);
     showNotification('success','Updated success')
     
   } catch (error) {
    showNotification("error","Some error occured..")
   }
  }
  const handleTrackClick = async (e)=>{
    e.stopPropagation()
    setClickedCard(data)
    setShowTrackModal(true);
  }

  useEffect(()=>{
    setIsActive(data?.is_active)
  },[data])

  return (
    <div style={{ position: "relative" }} onClick={() => setClickedCard(data)}>
      {/* <div style={{display:(data?.isCompleted)?'none':'flex',justifyContent:'center', position:'absolute', top:'-5px', zIndex:1000,left:'140px'}}><Icon iconName="dragIcon"/></div> */}
      <div className="product-card">
        <div className="image-container">
          <img
            src={data.preview_image} // Replace with the actual image URL
            alt={data.preview_image_alt}
            className="product-image"
          />
        </div>
        <div className="card-content">
          <div className="top-row">
            <button onClick={handleTrackClick} className="track-button">{t('TRACK')}</button>
            {data.is_completed ? (
              <div className="completed">
                <Icon iconName="completedIcon" /> {t('Completed')}
              </div>
            ) : (
              <label
                className="switch"
                style={{ marginTop: "-5px", marginRight: "5px" }}
                onClick={(e)=>e.stopPropagation()}
              >
                <input
                  type="checkbox"
                  style={{ fontSize: "20px" }}
                  checked={isActive}
                  onClick={handleSwitch}
                />
                <span className="slider round"></span>
              </label>
            )}
          </div>
          <p className="product-description">
            {truncate(data.title,{length:50})}
          </p>
          <div className="price-date">
            <span className="price">€{data.amount}</span>
            {/* <span className='line'></span> */}
            {data?.published_date && <span className="date">{moment(new Date(data?.published_date)).format(
                            "MMM DD, YYYY"
                          )}</span>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignCard;
