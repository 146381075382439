import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import styled from "styled-components";
import Button from "../../../../../components/Buttons/Button";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import TrackModal from "./TrackModal";
import Icon from "../../../../../components/Icon";
import Box from "../../../../../components/Box";
import truncate from "lodash/truncate";
import { useTranslation } from "react-i18next";
import {
  GridContextProvider,
  GridDropZone,
  GridItem,
  swap,
  move,
} from "react-grid-dnd";
import useActiveData from "../../../../../customHooks/useActiveData";

const StyledBox = styled(Box)`
  position: relative;
`;
const StyledIcon = styled(Icon)`
  position: absolute;
  right: 50%;
  left: 50%;
  bottom: 97.5%;
`;

const FundCard = () => {
  const websiteData = useActiveData();
  
  const [selectedId, setSelectedId] = useState(null);

  const [isReorder, setIsReorder] = useState(false);

  const [items, setItems] = useState(null);
  const {t} = useTranslation()
  const campaignList = useSelector(
    (state) => state.mosqueReducer.campaign_list
  );

  useEffect(() => {
    
    if (campaignList) {
      setItems(campaignList);
    }
  }, [campaignList]);
   

  const onChange = (sourceId, sourceIndex, targetIndex) => {
    const nextState = swap(items, sourceIndex, targetIndex);
    const updatedData = nextState.map((item, index) => {
      const data = { ...item };
      data.at_position = (index + 1).toString();
      return data;
    });
    return setItems(updatedData);
  };
  
  return (
    <div className="container">
      <div style={{}} className="">
        {items?.length === 0 ?
            <div className={"col-md-12"}>
              <br/><br/><br/>
              <div className="text-center">
                {websiteData?.CAMPAIGN_LIST_PAGE_BLANK_TEXT}
              </div>
            </div>
            :
            null
        }
        <GridContextProvider onChange={onChange}>
          <GridDropZone
            id="left"
            disableDrag={!isReorder}
            disableDrop={!isReorder}
            boxesPerRow={3}
            rowHeight={450}
            style={{ height: Math.ceil(items?.length / 3) * 450}}
          >
            {items?.map((item) => (
              <GridItem key={item._id} style={{cursor: isReorder ? 'grab' : 'default'}}>
                <div className="blog-card" style={{ marginRight: "20px" }}>
                  {!item.img_url ? (
                    <img
                      src="https://ik.imagekit.io/rzlzf40okya/elixir/defaultimg.svg"
                      alt=""
                      className="fund-card-img-size"
                    />
                  ) : (
                    <img
                      src={item?.img_url}
                      alt=""
                      className="fund-card-img-size"
                    />
                  )}
                  <div className="d-flex justify-content-between align-items-baseline">
                    <Button
                      type="primary"
                      bg="rgba(208, 0, 75, 0.08)"
                      mt="18px"
                      mb="18px"
                      textColor="#D0004B"
                      borderRadius="6px"
                      onClick={() => setSelectedId(item)}
                    >
                      <span style={{ color: "#D0004B" }}>{t('Track')}</span>
                    </Button>
                    <label className="switch">
                      <input type="checkbox" checked={item?.is_active} />
                      <span className="slider round"></span>
                    </label>
                  </div>
                  <h4 className="h4-height">
                    {truncate(item?.title, { length: 75 })}
                  </h4>
                  <div className="blog-profile">
                    <span>
                      {item?.currency ? item?.currency : "€"}
                      {item.targetAmount}
                    </span>
                    <div className="border-right"></div>
                    <span>
                      {moment(new Date(item.startDate)).format("DD MMM YYYY")}
                    </span>
                  </div>
                </div>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>
        {items?.length > 1 && 
          <div
            style={{}}
            className="d-flex justify-content-center align-items-center mt-4 "
          >
            <Button
              type={!isReorder ? "outlineColor" : "disabledOutlineColor"}
              mr="2rem"
              width = "200px"
              height ="50px"
              fontSize ="16px"
              padding ="12px 25px"
              
              onClick={() => setIsReorder(true)}
            >
              {t('Reorder')}
            </Button>
            <Button
              type={isReorder ? "primary" : "disabled"}
                width ="200px"
                height ="50px"
                fontSize= "16px"
                padding= "12px 24px"
             
              onClick={() => setIsReorder(false)}
            >
              {t('Save Changes')}
            </Button>
        </div>} 
      </div>

      <DefaultModal
        show={selectedId}
        onHide={() => setSelectedId(null)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <TrackModal item={selectedId} />
      </DefaultModal>
    </div>
  );
};

export default FundCard;


