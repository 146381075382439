import React, { useEffect } from "react";
import Button from "../../../../../components/Buttons";
import styled from "styled-components";
import {useParams, Link} from "react-router-dom";
import { useTranslation } from "react-i18next";

const DonateLink = styled(Link)`
  text-decoration: none;
`;

function Congratulation() {
    const handleClick = () => {
        window.location.href = "/login";
    }
    const {t} = useTranslation()
    useEffect(()=>{
      window.scrollTo(0, 700);
    },[])
  return (
    <div className="congrats-screen">
      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg" />
      <h4>{t('Gefeliciteerd')}</h4>
      <p>
      {t('We zullen alle verstrekte informatie bekijken en binnen 48 uur contact met u opnemen.')}
      </p>

      {/* <div className="d-flex justify-content-center mt-3">
      <DonateLink to={`/login`}>
           <button className="congratulation-button">Login Now</button>
      </DonateLink> */}
       {/* <button className="fill-bt" onClick={() => handleClick()}>Login Now</button> */}
      {/*  /!* <button className="fill-bt">Next Step</button> *!/*/}
      {/* </div> */}
    </div>
  );
}

export default Congratulation;
