import React, {useEffect, useState} from "react";
import Label from "../../../../../components/Label";
import BarChart from "../../../../../components/D3Charts/BarChart";
import {useDispatch, useSelector} from "react-redux";
import {getEntityStats} from "../../../mosqueAction";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

function Overview() {

    const activeEntity = useSelector(state => state?.mosqueReducer?.activeEntity);
    const monthOnMonthStats = useSelector(state => state?.mosqueReducer?.monthOnMonthStats);
    const {t} = useTranslation()
    const dispatch = useDispatch();

    useEffect(() => {
        if (activeEntity && activeEntity._id) {
            dispatch(getEntityStats({
                entity_id: activeEntity._id
            }))
        }
    }, [dispatch, activeEntity])

    const [memberStats, setMemberStats] = useState([]);

    const getCategories = () => {
        const dutchMonthNames = [
            "Jan", "Feb", "Mrt", "Apr", "Mei", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dec"
        ];
    
        let months = [];
        for (let i = 0; i < 6; i++) {
            const currentDate = dayjs().subtract(i, 'month').endOf('month');
            const monthName = dutchMonthNames[currentDate.month()];
            months.unshift(`${monthName}-${currentDate.year()}`);
        }
        return months;
    }    

    const getData = () => {
        let data = Array(6).fill(0);
        let months = [];
        data.forEach((item, index) => {
            months.unshift({
                year : dayjs().subtract(index, 'month').endOf('month').year(),
                month : dayjs().subtract(index, 'month').endOf('month').month(),
                amount: 0
            });
        });
        if (monthOnMonthStats?.length > 0) {
            months = months.map(item => {
                let amount = 0;
                monthOnMonthStats?.forEach(element => {
                    if (element.year === item.year && element.month === item.month + 1) {
                        amount = element.amount ? element.amount : 0;
                    }
                });
                item.amount = amount;
                return item;
            });
        }
        return months.map(item => item.amount);
    }

    return (
        <div className="col-md-7 col-lg-8">
            <div className="overview-card">
                <Label variant="text1" className="overview-head">{t('Overzicht')}</Label>
                <Label variant="text5" color="#7E7E7E" className="overview-disc">{t('Maandelijks ontvangen bedrag')}</Label>
                <div className="white_box" style={{height: "100%"}}>
                    <div className="" style={{height: "100%"}}>
                        <div style={{height: "100%"}}>
                            <BarChart
                                xdim={780}
                                ydim={400}
                                margin={{top: 0, bottom: 60, left: 0, right: 0}}
                                name={"Bedrag"}
                                data={getData()}
                                categories={getCategories()}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Overview;
