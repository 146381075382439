import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import Icon from "../../../../../components/Icon/Icon";
import Box from "../../../../../components/Box";
import Input from "../../../../../components/Input";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Label from "../../../../../components/Label";
import { MonthData } from "../../../../../constants/AdminData";
import {
  updateMemberInfo,
  fetchMemberReminderHistory,
  fetchMemberPaymentHistory,
} from "../../../adminAction";
import dayjs from "dayjs";
import { isMonthUpcoming } from "../../../../../util/Utility";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import { getFormattedTime } from "../../../../../util/timeUtility";
import truncate from "lodash/truncate";
import { useTranslation } from "react-i18next";

const ProfileModal = ({ selectedId, onHide }) => {
  const {t} = useTranslation()
  const dispatch = useDispatch();
  const [customerDetails, setCustomerDetails] = useState(null);
  const [errors, setErrors] = useState({});
  const [isNext, setIsNext] = useState(false);

  const [selectedItem, setSelectedItem] = useState({});
  const [isDisable, setIsDisable] = useState(false);

  const memberInfoList = useSelector(
    (state) => state.adminReducer.memberInfo?.memberInfoList
  );
  const memberInfoPayments = useSelector(
    (state) => state.adminReducer.memberInfo?.memberInfoPayments
  );
  const reminder_history = useSelector(
    (state) => state.adminReducer.memberInfo?.reminder_history
  );

  useEffect(() => {
    if (memberInfoList?.length > 0) {
      const data = memberInfoList.filter(
        (item) => item?._id === selectedId
      )?.[0];
      if (data) {
        setSelectedItem(data);
      }
    }
  }, [memberInfoList]);

  useEffect(() => {
    if (selectedItem?.member_id) {
      dispatch(
        fetchMemberReminderHistory({
          member_id: selectedItem?.member_id,
          pageNum: 1,
          pageSize: 100,
        })
      );
      dispatch(
        fetchMemberPaymentHistory({
          member_id: selectedItem?.member_id,
          pageNum: 1,
          pageSize: 100,
        })
      );
    }
  }, [selectedItem]);

  /*const renderPaymentStatus = (item, month) => {
    const filteredData = memberInfoPayments[item?.member_id]?.filter(
        (payment) => {
            return payment.payment_amount > 0 && dayjs(new Date(payment?.payment_due_date)).format("MMM").toLowerCase() === month
        }
    );
    if (filteredData?.length > 0) {
      return (
        <Box
        position="relative"
                    top="8px"
                    left="4px"
                    width="10px"
                    height="10px"
          className={
            filteredData?.[0]?.payment_status !== "paid"
              ? "red-color"
              : "green-color"
          }
        ></Box>
      );
    } else {
      return <Box ml="7px">{"-"}</Box>;
    }
  };*/

  const renderPaymentStatus = (item, month) => {
    const filteredData = item?.payments?.filter(payment => payment.month === month);
    if (filteredData?.length > 0) {
        const status = filteredData[0]?.status;
        return (
            <Box
                className={status === "SUCCESS" ? "green-color" : "red-color"}
                width="10px" 
                height="10px"
                top="5px"
                left="5px"
                position="relative"
            ></Box>
        );
    } else {
        return <Box ml="7px">{"-"}</Box>;
    }
}; 

  const disableMember = () => {
    if (selectedItem?.subscriptions?.[0]?.status === "ACTIVE") {
      setIsDisable(!isDisable);
    } else {
      updateStatus();
    }
  };

  const updateStatus = () => {
    const update = {};
    if (selectedItem?.subscriptions?.[0]?.status === "ACTIVE") {
      update.status = "INACTIVE";
      dispatch(updateMemberInfo(selectedItem?._id, update, true));
    } else if(selectedItem?.subscriptions?.[0]?.status === "INACTIVE"){
      update.status = "ACTIVE";
      dispatch(updateMemberInfo(selectedItem?._id, update, true));
    }
  };

  
  const handleUpdateDetails = (keyName, keyValue) => {
    setErrors({});
    setIsNext(true);
    const data = {...customerDetails};
    if (keyName === "address") {
        data["address"] = keyValue;
    } else {
        data[keyName] = keyValue;
    }
    setCustomerDetails(data);
}

  const formattedMembershipDate = selectedItem?.subscriptions && selectedItem.subscriptions.length > 0 ?
  getFormattedTime(selectedItem.subscriptions[0].date, "DD MMM YYYY, hh:mm a") :
  "-";

console.log("selected Member",selectedItem);
  return (
    <div>
      <div className="d-flex justify-content-between">
                <div>

                </div>
                <div onClick={() => onHide(null, false)}>
                    <Icon
                        style={{zIndex: 2000}}
                        iconName="cross"
                    />
                </div>
            </div>
        <div className="d-flex justify-content-between">
        <span className="d-flex align-items-baseline">
          {/* <h4>{selectedItem?.customer_id?.contact_name}</h4> */}
          <h4 style={{cursor: "pointer" ,paddingLeft:"20px",color:"black"}}
                    title={selectedItem?.name || 'NA'}
                    onMouseOver={(e) => (e.target.title = selectedItem?.name)}
                    onMouseOut={(e) => (e.target.title = selectedItem?.name ? truncate(selectedItem?.name, { length: 20 }) : 'NA')}
                    >{selectedItem?.name ? truncate(selectedItem?.name, { length: 20 })  : 'NA'}</h4>
                     {/* <div>
                        <label className="switch">
                        <input
                          type="checkbox"
                          onChange={() => disableMember()}
                          checked={selectedItem?.subscriptions?.[0]?.status === "ACTIVE" || null}
                        />
                        <span className="slider round"></span>
                        </label>
                    </div> */}
        </span>
        {/* <Icon iconName="cross" onClick={onHide} /> */}
      </div>
      <div  style={{paddingLeft:"20px"}}>
        {selectedItem?.paymentMode && (
          <small className="monthly">
            {selectedItem?.paymentMode === "manual" ? "handmatig" : null}
            {selectedItem?.paymentMode === "automatic" ? "automatic" : null}
            </small>
        )}
        {selectedItem?.paymentMethod && (
          <small className="autoPay">
            {selectedItem?.paymentMethod}
          </small>
        )}
      </div>
      <div className="details-card" style={{pointerEvents:'none'}}>
        <div className="d-flex">
          <Icon iconName="user" />
          <h5>{t('Personal Details')}</h5>
        </div>
        <Box mb="25px" className="d-flex justify-content-between member_ship">
          {/*<div>
            <Label color="#7E7E7E">Membership</Label>
            {selectedItem?.membership ? (
              <Label style={{fontWeight:"600",fontSize:"16px"}} mt="8px">{selectedItem?.membership}</Label>
            ) : (
              "-"
            )}
            </div>*/}
          <Box mr="20px">
            <Label color="#7E7E7E" mb="8px">
              {t('Membership Since')}
            </Label>
            <Box style={{fontWeight:"600",fontSize:"16px"}}>
            {formattedMembershipDate} 
            </Box>
          </Box>
        </Box>
        {/*<Box display="flex" flexDirection="column" marginBottom="20px">
        <Label color="#7E7E7E">Address</Label>
          {selectedItem?.customer_id?.address ? (
            <Label style={{fontWeight:"600",fontSize:"16px"}} mt="8px">
              {selectedItem?.customer_id?.address}{" "}
              {selectedItem?.customer_id?.country}{" "}
              {selectedItem?.customer_id?.pincode}
            </Label>
          ) : (
            "-"
          )}
          </Box>*/}
          <Box className="d-flex justify-content-between">
                    <div className="col-lg-6 input-width">
                            <label color="#7E7E7E" mb="8px" fontSize="14px">{t('Naam')}</label>
                            <Input
                                value={selectedItem?.name}
                                className="input-box"
                                onChange={(e) => handleUpdateDetails("contact_name", e.target.value)}
                            />
                            {errors.name && <ErrorMessage>{errors.name}</ErrorMessage>}
                        </div>
                    <div className="col-lg-6 input-width" style={{marginLeft:"10px"}}>
                            <label color="#7E7E7E" mb="8px" fontSize="14px">{t('E-mail')}</label>
                            <Input
                                value={selectedItem?.emailAddress}
                                className="input-box"
                                onChange={(e) => handleUpdateDetails("contact_email", e.target.value)}
                            />
                            {errors.email && <ErrorMessage>{errors.email}</ErrorMessage>}
                        </div>
                    </Box>
                    <Box className="d-flex justify-content-between">
                        <div className="col-lg-6 pt-2 input-width">
                            <label color="#7E7E7E" mb="8px" fontSize="14px">{t('Contactnummer')}</label>
                            <Input
                                value={selectedItem?.phoneNumber}
                                countryCodes={true}
                                countryCodeValue={selectedItem?.countryCode}
                                setCountryCode={(e) => handleUpdateDetails("country_code", e)}
                                onChange={(e) =>
                                    handleUpdateDetails("contact_phone", e.target.value)
                                }
                                border={selectedItem?.phoneNumber?.length ? "1px solid #606734" : null}
                                placeholder="785-785-4644"
                                className="input-box"
                                type="number"
                                pattern="[0-9]"
                                overflow="unset"
                            />
                            {errors.contact_phone && (
                                <ErrorMessage>{errors.contact_phone}</ErrorMessage>
                            )}
                        </div>
                        <div className="col-lg-6 pt-2 input-width" style={{marginLeft:"10px"}}>
                            <label color="#7E7E7E" mb="8px" fontSize="14px">{t('Land')}</label>
                            <Input
                                value={selectedItem?.country}
                                className="input-box"
                                onChange={(e) => handleUpdateDetails("contact_country", e.target.value)}
                            />
                        </div>
                        </Box>
                        <Box className="d-flex justify-content-between">
                        <div className="col-lg-6 pt-2 input-width">
                            <label color="#7E7E7E" mb="8px" fontSize="14px">{t('Stad')}</label>
                            <Input
                                value={selectedItem?.city}
                                className="input-box"
                                onChange={(e) => handleUpdateDetails("contact_city", e.target.value)}
                            />
                        </div>
                        </Box>
      </div>
      <div className="details-card">
        <div className="d-flex align-items-baseline">
          <Icon iconName="payment" mr="10px" />
          <h5>{t('PAYMENT HISTORY')}</h5>
        </div>
        <div className="d-flex justify-content-between member_ship">
          <div>
            <label style={{fontSize:"14px"}}>{t('Total Payments Received')}</label>
            <h6>
              <b>{selectedItem?.successPaymentsTotal}</b>
            </h6>
          </div>
          <div>
            <label style={{fontSize:"14px"}}>{t('Pending Payments')}</label>
            <h6>
              <b>{selectedItem?.pendingPaymentsTotal}</b>
            </h6>
          </div>
        </div>
        <div>
          <label className="label-missed-payments" style={{fontSize:"14px"}}>{t('Past Missed Payments')}</label>
          <h6>
            <b>{selectedItem?.missedPaymentsTotal}</b>
          </h6>
        </div>
        <br />
        <div className="month-day">
          {MonthData &&
            MonthData.map((item, i) => (
              <div>
                <h6>{item.name}</h6>
                {renderPaymentStatus(selectedItem, item?.name?.toLowerCase())}
              </div>
            ))}
        </div>
      </div>
      <div className="details-card">
        <div className="d-flex">
          <Icon iconName="historyMsg" mr="10px" />
          <h5>{t('REMINDER HISTORY')}</h5>
        </div>

        <div className="d-flex">
          <div className="sent-msg label-sent-msg mb-2">
            <label style={{fontSize:"13px", minWidth:"130px"}}>{t('Sent On')}</label>
          </div>
          <div className="message label-message mb-2">
            <label
               style={{paddingRight:"40px",fontSize:"13px"}}
            >{t('Message')}</label>
          </div>
        </div>
        {reminder_history?.length > 0 && reminder_history?.map((reminder,index) => (
            <div className="d-flex">
              <div className="sent-msg date-container">
                <h3>{dayjs(new Date(reminder.created_at)).format('DD MMM YY')}</h3>
                {index !== reminder_history.length - 1 && (
                                <div className="vertical-dotted-line"></div>
                                )}
              </div>
              <div className="message  label-msg">
                <label>{reminder.compiled_text}</label>
              </div>
          </div>
        ))}
      </div>

      <DefaultModal
        show={isDisable}
        onHide={() => setIsDisable(!isDisable)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={true}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="d-flex justify-content-between">
          <div></div>
          <div onClick={() => setIsDisable(!isDisable)}>
            <Icon iconName="blackCross" />
          </div>
        </div>
        <div className="congrats-screen">
          <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
          <Box mt="15px">
            <h4>Disable Member</h4>
          </Box>
          <Label color="#7E7E7E">
            {
              "You will loose this member’s profile, details and payment history"
            }
          </Label>

          <div className="d-flex justify-content-around mt-3">
            <button
              className="prev-bt"
              onClick={() => setIsDisable(!isDisable)}
            >
              {t('Cancel')}
            </button>
            <button
              className="fill-bt"
              onClick={() => {
                updateStatus();
                setIsDisable(!isDisable);
              }}
            >
              {t('Disable Member')}
            </button>
          </div>
        </div>
      </DefaultModal>
    </div>
  );
};

export default ProfileModal;
