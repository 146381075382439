import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../../../components/Icon";
import Button from "../../../../components/Buttons";
import { showNotification } from "../../../../util/Utility";
import { useTranslation } from "react-i18next";

export default function MyPlansPage() {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [isNext, setIsNext] = useState(null);

  const orgDetails = useSelector((state) => state.mosqueReducer.orgDetails);
  const plans = useSelector((state) => state.adminReducer.plans);

  const planList = plans.plan_list;

  useEffect(() => {
    if (orgDetails?.saas_subscription_plan_id) {
      setSelectedPlan(orgDetails?.saas_subscription_plan_id);
    }
  }, [orgDetails]);

  const handleChange = (id) => {
    if (orgDetails?.saas_subscription_plan_id === id) {
      setIsNext(false);
    } else {
      setIsNext(true);
    }
    const filteredPlan = planList.filter((item) => item?._id === id)?.[0];
    setSelectedPlan(filteredPlan?._id);
  };

  const renderRecurringType = (type) => {
    if (type === "quarterly") {
      return "quarter";
    }
    if (type === "monthly") {
      return "month";
    }
    return "year";
  };

  const handleOnChange = () => {
    showNotification("success", t("Plan updated successfully."));
  }

  return (
      <div className="row">
        {planList &&
            planList.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <div className="PlanCards">
                    <div
                        className={
                          selectedPlan === item?._id
                              ? "customCheckbox customCheckboxGreen"
                              : "customCheckbox customCheckboxGrey"
                        }
                    >
                      <Icon iconSize="xs" iconName="WhiteTick" />
                    </div>
                    <div className="planNo">
                      {'Plan'} - <span>{item?.plan_name}</span>
                    </div>
                    <div className="Dashborder"></div>
                    <div className="d-flex align-items-center price">
                      ${item.amount}
                      <del>8999</del>
                    </div>

                    {/* planBtnDisable */}
                    <button
                        disabled={selectedPlan === item?._id}
                        className={selectedPlan === item?._id ? "planBtn planBtnDisable" : "planBtn planBtnEnable"}
                        onClick={() => handleChange(item?._id)}
                    >
                      {t('Select Plan')}
                    </button>
                    <div className="planDetail">{t('Plan Details')}</div>
                    <ul>
                      <li>{t('Billing Frequency')} - {item?.recurring_type}</li>
                      <li>{t('Payment Date')} - {item?.payment_day ? item.payment_day : '5th'} of every {renderRecurringType(item?.recurring_type)}</li>
                    </ul>
                  </div>
                </div>
            ))}

        <Button
            type={!isNext ? "disabled" : "primary"}
            onClick={() => handleOnChange()}
            text={t("Save Details")}
            borderRadius="66px"
            textColor="#fff"
            width="15%"
            mt="4"
            mx="auto"
            height="60px"
        />
      </div>
  );
}
