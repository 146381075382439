import {
  ADD_NEW_BLOG,
  FETCH_BLOG,
  FETCH_ORGANISATION_ENTITIES,
  FETCHING_BLOG,
  FETCHING_CUSTOMER_LIST,
  FETCHING_CUSTOMER_LIST_FAILURE,
  FETCHING_CUSTOMER_LIST_SUCCESS,
  FETCHING_MOSQUE_BLOG_LIST_DATA,
  FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE,
  FETCHING_MOSQUE_BLOG_LIST_DATA_SUCCESS,
  FETCHING_STATUS,
  IS_AGENT_EXIST,
  LOAD_AGENT_DASHBOARD_STATS,
  LOAD_DASHBOARD_MONTH_ON_MONTH_STATS,
  OTP_SENT,
  OTP_SENT_FAILURE,
  OTP_SENT_SUCCESS,
  SAVE_EDIT_BLOG,
  SAVING_EDIT_BLOG,
  SENDING_REMINDER,
  SET_AGENT,
  VALIDATE_OTP,
  VALIDATE_OTP_FAILURE,
  VALIDATE_OTP_SUCCESS,
  SET_MEMBER_DETAILS_VISIBLE,
  LOAD_MEMBER_DETAILS,
  LOADING_MEMBER_DETAILS,
  LOADING_MEMBER_PAYMENT_HISTORY,
  LOAD_MEMBER_PAYMENT_HISTORY,
  LOADING_MEMBER_REMINDER_HISTORY,
  LOAD_MEMBER_REMINDER_HISTORY,
  FETCHING_CAMPAIGN_LIST,
  FETCHING_CAMPAIGN_LIST_FAILURE,
  FETCHING_CAMPAIGN_LIST_SUCCESS,
  ADD_NEW_CAMPAIGN,
  SAVING_CAMPAIGN,
  FETCHING_CAMPAIGN,
  FETCH_CAMPAIGN,
  SAVE_CAMPAIGN,
  FETCHING_PLAN_LIST,
  FETCHING_PLAN_LIST_SUCCESS,
  FETCHING_PLAN_LIST_FAILURE,
  UPDATE_PLAN,
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_FAILURE,
  UPDATE_ADDED_NEW_FLAG,
  SET_PLAN_DETAILS,
  ADD_NEW_PLAN,
  ADD_NEW_PLAN_SUCCESS,
  ADD_NEW_PLAN_FAILURE,
  DELETE_PLAN,
  DELETE_PLAN_SUCCESS,
  DELETE_PLAN_FAILURE,
  FINANCIALS_STATS_DATA,
  FINANCIALS_STATS_DATA_SUCCESS,
  FINANCIALS_STATS_DATA_FAILURE,
  FETCH_MEMBER_SUBS_COUNT_DETAILS,
  FETCH_MEMBER_SUBS_COUNT_DETAILS_SUCCESS,
  FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE,
  FETCH_MEMBER_REVENUE_STATS_DETAILS,
  FETCH_MEMBER_REVENUE_STATS_DETAILS_SUCCESS,
  FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE,
  UPDATE_AGENT,
  UPDATE_AGENT_SUCCESS,
  UPDATE_AGENT_FAILURE,
  FETCHING_ORG_DETAILS_SUCCESS,
  UPDATE_MEMBER_INFO_STATUS,
  UPDATE_MEMBER_INFO_STATUS_SUCCESS,
  UPDATE_MEMBER_INFO_STATUS_FAILURE,
  UPDATE_CUSTOMER,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_FAILURE,
  FETCHING_AGENT_LIST,
  FETCHING_AGENT_LIST_SUCCESS,
  FETCHING_AGENT_LIST_FAILURE,
  ADD_AGENT,
  ADD_AGENT_SUCCESS,
  ADD_AGENT_FAILURE,
  SET_OTP_SENT,
  FETCHING_APPROVAL_LIST,
  FETCHING_APPROVAL_LIST_FAILURE,
  FETCHING_APPROVAL_LIST_SUCCESS,
  MEMBER_APPROVAL_STATUS,
  SET_UPDATED_STATUS,
  MEMBER_DECLINE_STATUS,
  FETCHING_COUNTRIES,
  STORE_COUNTIRES,
  FETCHING_CITIES,
  STORE_CITIES,
} from "./mosqueActionType";
import { callApiV2 } from "../../util/apiCaller";
import {
  APP_TOKEN,
  USER_ID,
  USER_TYPE,
  USER_TYPE_MOSQUE,
} from "../../constants/appEnums";
import jwt_decode from "jwt-decode";
import { AUTHENTICATED } from "../../constants/authEnums";
import { showNotification } from "../../util/Utility";
import { setAuthState, setUserId } from "../appAction";

export const setFetchingDetails = (status) => ({
  type: FETCHING_STATUS,
  status,
});

export const setAgent = (agent) => ({
  type: SET_AGENT,
  agent,
});

export const setOtpSent = (status) => ({
  type: SET_OTP_SENT,
  status,
});

export const agentLogin =
  (body, isResend = false) =>
  async (dispatch) => {
    try {
      const res = await callApiV2({
        method: "post",
        endpoint: `agent/auth/login-otp`,
        body: { agent: body },
        isErrorSuppressed: true,
      });

      if (res?.status === "Success") {
        if (isResend) {
          showNotification("success", "Verstuur OTP opnieuw");
        }
        if (!!res?.data?.is_agent_found) {
          dispatch({
            type: OTP_SENT_SUCCESS,
            isOtpSent: true,
            isFetching: false,
          });
        } else if (!res?.data?.is_agent_found) {
          dispatch({
            type: IS_AGENT_EXIST,
            is_agent_found: true,
          });
        }
      } else {
        showNotification(
          "error",
          res?.data?.message?.message ||
            res?.data?.message ||
            "Er is iets misgegaan..."
        );
      }
    } catch (err) {
      dispatch({
        type: OTP_SENT_FAILURE,
        isFetching: false,
      });
    }
  };

export const validateOtpForAgent = (body) => async (dispatch) => {
  try {
    dispatch({
      type: VALIDATE_OTP,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `agent/auth/validate-otp`,
      body: { agent: body },
      isErrorSuppressed: true,
    });

    if (res?.status === "Success") {
      const { token } = res?.data;
      window.localStorage.setItem(APP_TOKEN, token);
      window.localStorage.setItem(USER_TYPE, USER_TYPE_MOSQUE);
      window.localStorage.setItem(USER_ID, res?.data?.agent?._id);
      const tempUser = jwt_decode(token);
      dispatch(setAgent(tempUser));
      dispatch(setAuthState(AUTHENTICATED));

      showNotification(
        "success",
        "Welkom bij het beheerdersdashboard van Mosqapp."
      );
      dispatch({
        type: VALIDATE_OTP_SUCCESS,
        payload: res?.data,
        isValidate: true,
        isFetching: false,
      });

      if (res?.data?.agent?._id) {
        setUserId(res?.data?.agent?._id);
      }

      window.location = `/mosque/${res?.data?.entity?._id}/dashboard`;
    } else {
      showNotification(
        "error",
        res?.data?.message ?? "Er is iets misgegaan..."
      );
      dispatch({
        type: VALIDATE_OTP_FAILURE,
        isFetching: false,
        otp_error: res?.data?.message,
      });
    }
  } catch (err) {
    dispatch({
      type: VALIDATE_OTP_FAILURE,
      isFetching: false,
    });
  }
};

export const updatePlan = (id, body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: UPDATE_PLAN,
      isFetching: true,
    });
    const activeEntity = getState()?.mosqueReducer?.activeEntity;
    const res = await callApiV2({
      method: "post",
      endpoint: `subscription_plan/${id}/update`,
      body: {
        subscription_plan: body,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: UPDATE_PLAN_SUCCESS,
        isFetching: false,
      });
      showNotification("success", "Lidmaatschap succesvol bijgewerkt");
      dispatch(fetchPlanList(activeEntity?._id));
      window.location.href = `/mosque/${activeEntity?._id}/subscriptions`;
    } else {
      showNotification("error", res?.data?.message || "Er is iets misgegaan..");
      dispatch({
        type: UPDATE_PLAN_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: UPDATE_PLAN_FAILURE,
      isFetching: false,
    });
  }
};

export const updateAddedNewFlag = (status) => ({
  type: UPDATE_ADDED_NEW_FLAG,
  status,
});

export const setPlanDetails = (keyName, keyValue) => ({
  type: SET_PLAN_DETAILS,
  keyName,
  keyValue,
});

export const addNewPlan = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_NEW_PLAN,
      isFetching: true,
    });
    if (body) {
      const res = await callApiV2({
        method: "post",
        endpoint: `subscription_plan/new`,
        body: {
          subscription_plan: body,
        },
      });
      if (res?.status === "Success") {
        const activeEntity = getState()?.mosqueReducer?.activeEntity;
        dispatch({
          type: ADD_NEW_PLAN_SUCCESS,
          payload: res?.data,
          isFetching: false,
          isAddedNew: true,
        });
        showNotification("success", "Lidmaatschap succesvol toegevoegd");
        dispatch(fetchPlanList(activeEntity?._id));
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: ADD_NEW_PLAN_FAILURE,
          isFetching: false,
        });
      }
    }
  } catch (err) {
    dispatch({
      type: ADD_NEW_PLAN_FAILURE,
      isFetching: false,
    });
  }
};

export const deletePlan = (id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: DELETE_PLAN,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `subscription_plan/${id}/remove`,
      isErrorSuppressed: true,
    });
    if (res?.status === "Success") {
      dispatch({
        type: DELETE_PLAN_SUCCESS,
        isFetching: false,
      });
      showNotification("success", "Lidmaatschap succesvol verwijderd");
      const activeEntity = getState()?.mosqueReducer?.activeEntity;
      dispatch(fetchPlanList(activeEntity?._id));
    } else {
      showNotification("error", res?.data?.message || "Er is iets misgegaan..");
      dispatch({
        type: DELETE_PLAN_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: DELETE_PLAN_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchOrganisationEntities =
  (organisation_id) => async (dispatch) => {
    try {
      const res = await callApiV2({
        method: "get",
        endpoint: `agent/dashboard/organisation/${organisation_id}/entity-list`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_ORGANISATION_ENTITIES,
          entities: res.data,
        });
        dispatch(fetchAgentDashboardStats(res?.data[0]._id));
        dispatch(fetchApprovalList(res?.data[0]?._id));
        dispatch(fetchPlanList(res?.data[0]?._id));
      }
    } catch (err) {
      console.log(err);
    }
  };

export const fetchAgentProfile = (agent_id) => async (dispatch) => {
  try {
    const res = await callApiV2({
      method: "get",
      endpoint: `agent/${agent_id}`,
    });
    if (res?.status === "Success") {
      dispatch(setAgent(res?.data?.agent));
      dispatch(fetchOrganisationEntities(res?.data?.agent?.organisation_id));
      dispatch(fetchOrganisationDetails(res?.data?.agent?.organisation_id));
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchOrganisationDetails =
  (organisation_id) => async (dispatch) => {
    try {
      const res = await callApiV2({
        method: "get",
        endpoint: `organisation/${organisation_id}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCHING_ORG_DETAILS_SUCCESS,
          organisation: res.data?.organisation,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

export const fetchAgentDashboardStats = (entity_id) => async (dispatch) => {
  try {
    const res = await callApiV2({
      method: "get",
      endpoint: `agent/dashboard/entity/${entity_id}/home`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: LOAD_AGENT_DASHBOARD_STATS,
        dashboardStats: res.data,
      });
    }
  } catch (err) {
    console.log(err);
  }
};

export const fetchPlanList = (entity_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_PLAN_LIST,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `subscription_plan/list`,
      body: {
        filters: {
          entity_id,
        },
        pageNum: 1,
        pageSize: 50,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCHING_PLAN_LIST_SUCCESS,
        planList: res?.data?.subscriptionPlanList || [],
        planListCount: res?.data?.subscriptionPlanCount || 0,
        isFetching: false,
      });
    } else if (res?.status === "Error") {
      dispatch({
        type: FETCHING_PLAN_LIST_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    showNotification("error", "Er is iets misgegaan..");
    dispatch({
      type: FETCHING_PLAN_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const financialsStatsData =
  (activeEntityId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FINANCIALS_STATS_DATA,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "get",
        endpoint: `agent/dashboard/entity/${activeEntityId}/financial/home`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FINANCIALS_STATS_DATA_SUCCESS,
          isFetching: false,
          details: res?.data,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: FINANCIALS_STATS_DATA_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: FINANCIALS_STATS_DATA_FAILURE,
        isFetching: false,
      });
    }
  };

export const fetchMemberSubsCount =
  (start_date, end_date, entityId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_MEMBER_SUBS_COUNT_DETAILS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "get",
        endpoint: `agent/dashboard/entity/${entityId}/member-subscription-count`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_MEMBER_SUBS_COUNT_DETAILS_SUCCESS,
          isFetching: false,
          details: res?.data?.data,
        });
      } else {
        showNotification("error", "Er is iets misgegaan..");
        dispatch({
          type: FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  };

export const fetchMemberRevenueStats =
  (start_date, end_date, entityId) => async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCH_MEMBER_REVENUE_STATS_DETAILS,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "get",
        endpoint: `agent/dashboard/entity/${entityId}/payment-stats?start_date=${start_date}&end_date=${end_date}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCH_MEMBER_REVENUE_STATS_DETAILS_SUCCESS,
          isFetching: false,
          details: res?.data,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE,
        isFetching: false,
      });
    }
  };

export const fetchAgentBlogList =
  ({ entity_id, pageNum, pageSize }) =>
  async (dispatch) => {
    try {
      dispatch({
        type: FETCHING_MOSQUE_BLOG_LIST_DATA,
        is_fetching_blog_list: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `blog/list`,
        body: {
          filters: {
            entity_id,
          },
          pageNum,
          pageSize,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCHING_MOSQUE_BLOG_LIST_DATA_SUCCESS,
          blog_list: res.data.blogList,
          blog_page_num: pageNum,
          blog_page_size: pageSize,
          total_blog_count: res.data.blogCount,
        });
      } else {
        dispatch({
          type: FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE,
          is_fetching_blog_list: true,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE,
        is_fetching_blog_list: false,
      });
    }
  };

export const addNewBlog = (blog) => async (dispatch, getState) => {
  try {
    dispatch({
      type: SAVING_EDIT_BLOG,
      is_saving_blog: true,
    });
    const activeEntity = getState()?.mosqueReducer?.activeEntity;
    const res = await callApiV2({
      method: "post",
      endpoint: `blog/new`,
      body: {
        blog,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: ADD_NEW_BLOG,
        blog: res.data.blog,
      });
      showNotification("success", "Nieuw nieuwsbericht succesvol gepubliceerd");
      window.location.href = `/mosque/${activeEntity?._id}/blogs`;
    } else {
      dispatch({
        type: SAVING_EDIT_BLOG,
        is_saving_blog: false,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: SAVING_EDIT_BLOG,
      is_saving_blog: false,
    });
  }
};

export const updateBlog =
  (blog_id, blog, isDelete = false) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SAVING_EDIT_BLOG,
        is_saving_blog: true,
      });
      const activeEntity = getState()?.mosqueReducer?.activeEntity;
      const res = await callApiV2({
        method: "post",
        endpoint: `blog/${blog_id}/update`,
        body: {
          blog,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: SAVE_EDIT_BLOG,
          blog: res.data.blog,
        });
        showNotification(
          "success",
          `${
            isDelete
              ? "Nieuwsbericht succesvol verwijderd"
              : "Nieuwsbericht succesvol bijgewerkt"
          } `
        );
        window.location.href = `/mosque/${activeEntity?._id}/blogs`;
      } else {
        dispatch({
          type: SAVING_EDIT_BLOG,
          is_saving_blog: false,
        });
      }
    } catch (err) {
      console.log(err);
      dispatch({
        type: SAVING_EDIT_BLOG,
        is_saving_blog: false,
      });
    }
  };

export const fetchSingleBlog = (blog_id) => async (dispatch) => {
  try {
    dispatch({
      type: FETCHING_BLOG,
      is_saving_blog: true,
    });
    const res = await callApiV2({
      method: "get",
      endpoint: `blog/${blog_id}`,
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCH_BLOG,
        blog: res.data.blog,
      });
    } else {
      dispatch({
        type: FETCHING_BLOG,
        is_saving_blog: false,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: FETCHING_BLOG,
      is_saving_blog: false,
    });
  }
};

export const fetchCustomerInfoList =
  ({ entity_id, member_id, filters, pageNum, pageSize }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: FETCHING_CUSTOMER_LIST,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `agent/entity/${entity_id}/customers-list`,
        body: {
          filters,
          member_id,
          pageNum: pageNum,
          pageSize: pageSize,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: FETCHING_CUSTOMER_LIST_SUCCESS,
          list: res?.data?.list || [],
          count: res?.data?.count || 0,
          payments: res?.data?.payments || [],
          customerPayments: res?.data?.memberPayments || {},
          isFetching: false,
          pageNum: pageNum,
          pageSize: pageSize,
        });
      } else if (res?.status === "Error") {
        dispatch({
          type: FETCHING_CUSTOMER_LIST_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: FETCHING_CUSTOMER_LIST_FAILURE,
        isFetching: false,
      });
    }
  };

export const sendMemberReminders =
  ({
    entity_id,
    members,
    isProfileModal = false,
    pageNum,
    pageSize,
    activeMember,
  }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: SENDING_REMINDER,
        is_sending_reminder: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `agent/entity/${entity_id}/send-members-reminders`,
        body: {
          members,
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        dispatch({
          type: SENDING_REMINDER,
          is_sending_reminder: false,
        });
        showNotification("success", "Reminders Sent");
      } else {
        showNotification("error", res?.error || "Er is iets misgegaan..");
        dispatch({
          type: SENDING_REMINDER,
          is_sending_reminder: false,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: SENDING_REMINDER,
        is_sending_reminder: false,
      });
    }
  };

export const getEntityStats =
  ({ entity_id, start_date, end_date }) =>
  async (dispatch, getState) => {
    try {
      let query = `agent/dashboard/entity/${entity_id}/entity-stats`;
      if (start_date && end_date) {
        query = `${query}?start_date=${start_date}&end_date=${end_date}`;
      }
      const res = await callApiV2({
        method: "get",
        endpoint: `${query}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: LOAD_DASHBOARD_MONTH_ON_MONTH_STATS,
          monthOnMonthStats: res.data,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
    }
  };

export const setMemberDetailsVisible =
  (member, val) => async (dispatch, getState) => {
    dispatch({
      type: SET_MEMBER_DETAILS_VISIBLE,
      isMemberModalVisible: val,
      activeMember: member,
    });
  };

export const fetchMemberDetails =
  (member_subscription_id) => async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_MEMBER_DETAILS,
        isFetching: true,
      });
      let query = `member/member-subscription/${member_subscription_id}/details`;
      const res = await callApiV2({
        method: "get",
        endpoint: `${query}`,
      });
      if (res?.status === "Success") {
        dispatch({
          type: LOAD_MEMBER_DETAILS,
          isFetching: false,
          users: res.data.users,
          //customer: res.data.customer,
        });
      } else {
        dispatch({
          type: LOADING_MEMBER_DETAILS,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: LOADING_MEMBER_DETAILS,
        isFetching: false,
      });
      showNotification("error", "Er is iets misgegaan..");
    }
  };

export const fetchMemberPaymentHistory =
  ({ member_id, pageNum, pageSize }) =>
  async (dispatch, getState) => {
    try {
      dispatch({
        type: LOADING_MEMBER_PAYMENT_HISTORY,
        isFetching: true,
      });
      let query = `member/${member_id}/payment-history`;
      const res = await callApiV2({
        method: "post",
        endpoint: `${query}`,
        body: {
          pageNum,
          pageSize,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: LOAD_MEMBER_PAYMENT_HISTORY,
          isFetching: false,
          payment_history: res.data.payments,
          total_payment_count: res.data.total_count,
        });
      } else {
        dispatch({
          type: LOADING_MEMBER_PAYMENT_HISTORY,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: LOADING_MEMBER_PAYMENT_HISTORY,
        isFetching: false,
      });
      showNotification("error", "Er is iets misgegaan..");
    }
  };

/*export const fetchMemberReminderHistory = ({member_id, pageNum, pageSize} ) => async (dispatch, getState) => {
    try {
        dispatch({
            type: LOADING_MEMBER_REMINDER_HISTORY,
            isFetching: true,
        });
        let query = `member/${member_id}/reminder-history`;
        const res = await callApiV2({
            method: "post",
            endpoint: `${query}`,
            body: {
                pageNum,
                pageSize
            }
        });
        if (res?.status === "Success") {
            dispatch({
                type: LOAD_MEMBER_REMINDER_HISTORY,
                isFetching: false,
                reminder_history: res.data.tasks,
                total_reminder_count: res.data.total_count,
            });
        } else {
            dispatch({
                type: LOADING_MEMBER_REMINDER_HISTORY,
                isFetching: false,
            });
        }
    } catch (err) {
        dispatch({
            type: LOADING_MEMBER_REMINDER_HISTORY,
            isFetching: false,
        });
        showNotification("error", "Er is iets misgegaan..");
    }
};*/

export const fetchCampaign = (body) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_CAMPAIGN_LIST,
      is_fetching_campaign_list: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `campaigns/list`,
      body: body,
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCHING_CAMPAIGN_LIST_SUCCESS,
        campaign_list: res?.data?.campaignsList || [],
        total_campaign_count: res?.data?.campaignsCount || 0,
        campaign_page_num: body.pageNum,
        campaign_page_size: body.pageSize,
        is_fetching_campaign_list: false,
      });
    } else if (res?.status === "Error") {
      dispatch({
        type: FETCHING_CAMPAIGN_LIST_FAILURE,
        is_fetching_campaign_list: false,
      });
    }
  } catch (err) {
    showNotification("error", "Er is iets misgegaan..");
    dispatch({
      type: FETCHING_CAMPAIGN_LIST_FAILURE,
      is_fetching_campaign_list: false,
    });
  }
};
export const updateAgentDetails =
  (id, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_AGENT,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `agent/${id}/update`,
        body: {
          agent: details,
        },
        isErrorSuppressed: true,
      });
      if (res?.status === "Success") {
        showNotification("success", "Profiel succesvol bijgewerkt.");
        dispatch({
          type: UPDATE_AGENT_SUCCESS,
          details: res?.data?.agent,
          isFetching: false,
        });
      } else {
        showNotification(
          "error",
          res?.data?.message || "Er is iets misgegaan.."
        );
        dispatch({
          type: UPDATE_AGENT_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      dispatch({
        type: UPDATE_AGENT_FAILURE,
        isFetching: false,
      });
    }
  };

export const updateMemberSubscriptionInfo =
  (id, details, isDisable, refetchCustomerList) =>
  async (dispatch, getState) => {
    try {
      console.log("insdie api");
      dispatch({
        type: UPDATE_MEMBER_INFO_STATUS,
        isFetching: true,
      });
      console.log("details:", details);
      const res = await callApiV2({
        method: "post",
        endpoint: `member_subscription/${id}/update`,
        body: {
          member_subscription: details,
        },
      });
      if (res?.status === "Success") {
        refetchCustomerList();
        dispatch({
          type: UPDATE_MEMBER_INFO_STATUS_SUCCESS,
          details: res?.data?.member_subscription,
          isFetching: false,
          isDisable: isDisable,
        });
        // Show success notification
        const notificationMessage = isDisable
          ? details.status === "ACTIVE"
            ? "Member succesvol ingeschakeld."
            : "Member succesvol uitgeschakeld."
          : "Lidgegevens succesvol bijgewerkt.";
        showNotification("success", notificationMessage);
      } else if (res?.status === "Error") {
        dispatch({
          type: UPDATE_MEMBER_INFO_STATUS_FAILURE,
          isFetching: false,
        });
        // Show error notification
        showNotification("error", "Mislukt om lidgegevens bij te werken.");
      }
    } catch (err) {
      console.log(err);
      showNotification("error", "Er is iets misgegaan...");
      dispatch({
        type: UPDATE_MEMBER_INFO_STATUS_FAILURE,
        isFetching: false,
      });
    }
  };

export const addNewCampaign = (campaigns) => async (dispatch) => {
  try {
    dispatch({
      type: SAVING_CAMPAIGN,
      is_saving_camapign: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `campaigns/new/`,
      body: {
        campaigns,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Campaign added successfully.");
      dispatch({
        type: ADD_NEW_CAMPAIGN,
        campaigns: res.data.campaigns,
      });
      window.location.reload();
    } else {
      dispatch({
        type: SAVING_CAMPAIGN,
        is_saving_campaign: false,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: SAVING_CAMPAIGN,
      is_saving_campaign: false,
    });
  }
};

export const fetchSingleCampaign = (campaign_id) => async (dispatch) => {
  try {
    dispatch({
      type: FETCHING_CAMPAIGN,
      is_saving_camapign: true,
    });
    const res = await callApiV2({
      method: "get",
      endpoint: ``,
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCH_CAMPAIGN,
        campaigns: res.data.campaigns,
      });
    } else {
      dispatch({
        type: FETCHING_CAMPAIGN,
        is_saving_camapign: false,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: FETCHING_CAMPAIGN,
      is_saving_camapign: false,
    });
  }
};

export const fetchCountries = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCHING_COUNTRIES,
      isFetchingCountries: true,
    });
    dispatch({
      type: STORE_CITIES,
      cities: [],
    });
    const res = await callApiV2({
      method: "get",
      endpoint: `/api/v1/get-countries`,
      isMobileServer: true,
    });
    if (res?.status_code === 200) {
      delete res.status_code;

    //   const sortedRes = Object.values(res).sort((a, b) => {
    //     let x = a.country.toLowerCase();
    //     let y = b.country.toLowerCase();

    //     if (x > y) { return 1; }
    //     if (x < y) { return -1; }
    //     return 0;
    // });
      const reqCountires = Object.values(res).filter((ele)=>ele.country==='Netherlands');
      dispatch({
        type: STORE_COUNTIRES,
        countries: reqCountires,
      });
    } else {
      dispatch({
        type: FETCHING_COUNTRIES,
        isFetchingCountries: false,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: FETCHING_COUNTRIES,
      isFetchingCountries: false,
    });
  }
};

export const fetchCities = (country_name) => async (dispatch) => {
  try {
    dispatch({
      type: FETCHING_CITIES,
      isFetchingCities: true,
    });
    const res = await callApiV2({
      method: "get",
      endpoint: `/api/v1/get-cities-by-country?country=${country_name}`,
      isMobileServer: true,
    });
    if (res?.status_code === 200) {
      dispatch({
        type: STORE_CITIES,
        cities: res?.cities?.map((item) => ({ label: item, value: item })),
      });
    }
    dispatch({
      type: FETCHING_CITIES,
      isFetchingCities: false,
    });
  } catch (err) {
    console.log(err);
    dispatch({
      type: FETCHING_CITIES,
      isFetchingCities: false,
    });
  }
};

export const updateCampaign = (campaign_id, campaigns) => async (dispatch) => {
  try {
    dispatch({
      type: SAVING_CAMPAIGN,
      is_saving_camapign: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: ``,
      body: {
        campaigns,
      },
    });
    if (res?.status === "Success") {
      showNotification("success", "Campaign update successfully.");
      dispatch({
        type: SAVE_CAMPAIGN,
        campaigns: res.data.campaigns,
      });
    } else {
      dispatch({
        type: SAVING_CAMPAIGN,
        is_saving_camapign: false,
      });
    }
  } catch (err) {
    console.log(err);
    dispatch({
      type: SAVING_CAMPAIGN,
      is_saving_camapign: false,
    });
  }
};

export const setCustomerUpdateStatus = (status) => ({
  type: SET_UPDATED_STATUS,
  status,
});

export const updateCustomerInfo =
  (id, details) => async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_CUSTOMER,
        isFetching: true,
      });
      const res = await callApiV2({
        method: "post",
        endpoint: `customer/${id}/update`,
        body: {
          customer: details,
        },
      });
      if (res?.status === "Success") {
        dispatch({
          type: UPDATE_CUSTOMER_SUCCESS,
          details: res?.data?.customer,
          isUpdatedSuccessfully: true,
          isFetching: false,
        });
        showNotification("success", "Lidinfostatus bijgewerkt.");
      } else if (res?.status === "Error") {
        dispatch({
          type: UPDATE_CUSTOMER_FAILURE,
          isFetching: false,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan...");
      dispatch({
        type: UPDATE_CUSTOMER_FAILURE,
        isFetching: false,
      });
    }
  };

export const addNewAgent = (agentDetails) => async (dispatch, getState) => {
  try {
    dispatch({
      type: ADD_AGENT,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `agent/new`,
      body: {
        agent: agentDetails,
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: ADD_AGENT_SUCCESS,
        agent: res?.data?.agent,
        isFetching: false,
      });
      showNotification("success", "Agent succesvol toegevoegd.");
      window.location.reload();
    } else if (res?.status === "Error") {
      dispatch({
        type: ADD_AGENT_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    showNotification("error", "Er is iets misgegaan..");
    dispatch({
      type: ADD_AGENT_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchExistingAgent = (org_id) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_AGENT_LIST,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `agent/list`,
      body: {
        pageNum: 1,
        pageSize: 50,
        filters: {
          organisation_id: org_id,
        },
      },
    });
    if (res?.status === "Success") {
      dispatch({
        type: FETCHING_AGENT_LIST_SUCCESS,
        agentList: res?.data?.agentList,
        agentCount: res?.data?.agentCount,
        isFetching: false,
      });
    } else if (res?.status === "Error") {
      dispatch({
        type: FETCHING_AGENT_LIST_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    showNotification("error", "Er is iets misgegaan..");
    dispatch({
      type: FETCHING_AGENT_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const fetchApprovalList = (entityId) => async (dispatch, getState) => {
  try {
    dispatch({
      type: FETCHING_APPROVAL_LIST,
      isFetching: true,
    });
    const res = await callApiV2({
      method: "post",
      endpoint: `agent/dashboard/entity/${entityId}/approval-list`,
    });
    console.log("fetchapprovallist", res);
    if (res?.status === "Success") {
      dispatch({
        type: FETCHING_APPROVAL_LIST_SUCCESS,
        isFetching: false,
        list: res?.data?.list,
        count: res?.data?.count,
      });
    } else {
      showNotification("error", "Er is iets misgegaan..");
      dispatch({
        type: FETCHING_APPROVAL_LIST_FAILURE,
        isFetching: false,
      });
    }
  } catch (err) {
    dispatch({
      type: FETCHING_APPROVAL_LIST_FAILURE,
      isFetching: false,
    });
  }
};

export const memberApprove =
  (memberSubId, approvalId, userId) => async (dispatch, getState) => {
    try {
      const activeEntity = getState()?.mosqueReducer?.activeEntity;
      const pageNum = getState()?.mosqueReducer?.customerInfo?.pageNum;
      const pageSize = getState()?.mosqueReducer?.customerInfo?.pageSize;
      const res = await callApiV2({
        method: "post",
        endpoint: `onboarding/member/${memberSubId}/approval/${approvalId}/approve`,
      });
      if (res?.status === "Success") {
        showNotification("success", "Lid succesvol goedgekeurd.");
        console.log("usreId-->", userId);
        fetch(
          `https://engine.mosqapp.com/api/v1/send-notification?userId=${userId}`,
          {
            method: "POST",
            body: JSON.stringify({
              message: {
                notification: {
                  title: "Account approval success",
                  body: "Welcome to MOSQAPP. The mosque admin has approved your account. Please login",
                },
              },
            }),
            headers: {
              "Content-type": "application/json; charset=UTF-8",
            },
          }
        )
          .then((response) => response.json())
          .then((json) => console.log(json));

        dispatch(
          fetchCustomerInfoList({
            entity_id: activeEntity?._id,
            pageSize: pageSize,
            pageNum: pageNum,
          })
        );
        dispatch({
          type: MEMBER_APPROVAL_STATUS,
          approvalId,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
    }
  };

export const memberDecline =
  (approvalId, activeEntityId) => async (dispatch, getState) => {
    try {
      const res = await callApiV2({
        method: "post",
        endpoint: `agent/dashboard/approval/decline/${approvalId}`,
      });
      if (res?.status === "Success") {
        showNotification("success", "Agent afgewezen.");
        dispatch({
          type: MEMBER_DECLINE_STATUS,
          approvalId,
        });
      }
    } catch (err) {
      showNotification("error", "Er is iets misgegaan..");
    }
  };
