import React from 'react';
import './ProgressBar.css';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ProgressBar = ({ donationCollected, goalAmount, totalDonors, highestDonation }) => {
  // Calculate the progress percentage
  const progressPercentage = (donationCollected / goalAmount) * 100;
  const {t} = useTranslation()

  return (
    <div className="progress-section">
      <div className="progress-bar-container">
        <p style={{fontSize:'20px',fontWeight:500, color:'#1a1a1a', marginBottom:'50px'}}>Consectetur adipiscing elitsed</p>
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progressPercentage}%` }} // Dynamically set width
          >
            
            <Tooltip
            //   key={payment._id}
              title={
                <div>
                 <strong>€{donationCollected}</strong>
                </div>
              }
            >
              <div className="progress-pill"></div>
            </Tooltip>
          </div>
          <div
            className="progress-bar-label"
            style={{ left: `${progressPercentage}%` }} // Adjust the label position
          >
            €{donationCollected}
          </div>
        </div>
        <div className="progress-values">
          <span style={{fontSize:'20px', fontWeight:'700',color:'#1a1a1a'}} >€0</span>
          <span style={{fontSize:'20px', fontWeight:'700',color:'#1a1a1a'}}>€{goalAmount}</span>
        </div>
      </div>

      <div className="progress-info">
        <div className="info-item">
          <span style={{color:'#7E7E7E', fontSize:'16px', fontWeight:'400'}}>{t('Total Donors')}</span>
          <strong style={{fontWeight:'600',fontSize:'18px', color:'#1a1a1a'}}>{totalDonors}</strong>
        </div>
        <div className="info-item">
          <span style={{color:'#7E7E7E', fontSize:'16px', fontWeight:'400'}}>{t('Donation Collected')}</span>
          <strong style={{fontWeight:'600',fontSize:'18px', color:'#1a1a1a'}}>€{donationCollected}</strong>
        </div>
        <div className="info-item">
          <span style={{color:'#7E7E7E', fontSize:'16px', fontWeight:'400'}}>{t('Highest Donation')}</span>
          <strong style={{fontWeight:'600',fontSize:'18px', color:'#1a1a1a'}}>€{highestDonation}</strong>
        </div>
      </div>
    </div>
  );
};

export default ProgressBar;
