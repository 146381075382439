import React from "react";
import { useTranslation } from "react-i18next";
function Banner() {
  const {t} = useTranslation()
  return (
    <div className="login_banner">
      
      <div className="container">
        <div className="logo_im">LOGO</div>
        <div className="login_content">
          <h3>{t('Pay Now')}</h3>
          <p>
            {t('Antiren sän nir stenorat lyssna in. Sär gengen.')} <br />
            {t('Kävagisk nepäskade label supraktig gigartad.')}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Banner;
