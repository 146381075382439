import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import ExistingAdmins from "./ExistingAdmins";
import AddNewAdmin from "./AddNewAdmin";
import Box from "../../../../../components/Box";
import { useTranslation } from "react-i18next";

const EXISTING_SCREEN = "existing";
const ADD_NEW_SCREEN = "add";

const AdminTab = () => {
  const location = useLocation();
  const [selectedSegment, setSelectedSegment] = useState(EXISTING_SCREEN);
  const {t} = useTranslation()

  const setSegment = (segment) => {
    setSelectedSegment(segment);
  };

  useEffect(() => {
    if (location?.search === "?new") {
      setSelectedSegment(ADD_NEW_SCREEN);
    }
    if (location?.search === "?exist") {
      setSelectedSegment(EXISTING_SCREEN);
    }
  }, [location]);

  const renderSegment = () => {
      return "Existing Admin";
  };

  return (
    <div>
      <Box
        display="flex"
        alignItems="center"
        cursor="pointer"
        justifyContent="space-between"
      >
        <Box fontSize="22px" fontWeight={700}>
          {selectedSegment === EXISTING_SCREEN ? renderSegment() : "Add Admin"}
        </Box>
        <ul className="d-flex plan-modal">
          <li
            className="information col-6"
            style={{
              fontWeight: selectedSegment === EXISTING_SCREEN ? "600" : "600",
              backgroundColor:
                selectedSegment === EXISTING_SCREEN ? "#D0004B" : "#fff",
              color: selectedSegment === EXISTING_SCREEN ? "#fff" : "#D0004B",
              borderRadius:
                selectedSegment === EXISTING_SCREEN
                  ? "50px 0px 0px 50px"
                  : "50px 0px 0px 50px",
            }}
            onClick={() => setSegment(EXISTING_SCREEN)}
          >
            {t('Existing Admin')}{" "}
          </li>
          <li
            className="screen-details col-6"
            style={{
              fontWeight: selectedSegment === ADD_NEW_SCREEN ? "600" : "600",
              backgroundColor:
                selectedSegment === ADD_NEW_SCREEN ? "#D0004B" : "#fff",
              color: selectedSegment === ADD_NEW_SCREEN ? "#fff" : "#D0004B",
              borderRadius:
                selectedSegment === ADD_NEW_SCREEN
                  ? "0px 50px 50px 0px"
                  : "0px 50px 50px 0px",
            }}
            onClick={() => setSegment(ADD_NEW_SCREEN)}
          >
            {t('Add Admin')}{" "}
          </li>
        </ul>
      </Box>
      <Box mt="25px">
        {selectedSegment === EXISTING_SCREEN ? (
          <ExistingAdmins/>
        ) : (
            <AddNewAdmin setSegment={setSegment} />
        )}
      </Box>
    </div>
  );
};

export default AdminTab;
