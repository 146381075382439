import React, { useState, useEffect } from "react";
import Checkbox from "../../../../../components/Checkbox/Checkbox"
import Box from "../../../../../components/Box";
import Icon from "../../../../../components/Icon";
import Buttons from "../../../../../components/Buttons";
import styled from "styled-components";
import useOutsideClick from "../../../../../customHooks/useOutsideClick";
import Label from "../../../../../components/Label";
import { useTranslation } from "react-i18next";
const Container = styled(Box)`
  height: 320px;
  min-height: 320px;
  max-height: 320px;
  overflow:auto;


  overflow-x:hidden;
  ::-webkit-scrollbar {
    width: 3px;
    height: 10px;
  }
  ::-webkit-scrollbar-thumb {
    background-image: url("https://ik.imagekit.io/rzlzf40okya/elixir/Line.svg?updatedAt=1689592501360");
    background-repeat: no-repeat;
    // background: #9197B3;
    // border-radius: 5px;
  }
`;

const IconContainer = styled(Icon)`
  transform: ${props => props.isOpen ? 'rotate(180deg)' : 'unset'};
`;

const DropdownItemBox = styled(Box)`
  position: absolute;
  background: rgb(245, 245, 245);
  z-index: 99;
  padding: 15px;
  margin-left: -10px;
  border-radius: ${props => props.isOpen ? '0 0 8px 8px' : '8px'};
`;


const CheckBoxFilterBy = ({ value, options, onChange }) => {
    const [open, setOpen] = useState(false);
    const {t} = useTranslation()
    const [iconTheme, setIconTheme] = useState({});
    const [selectedItems, setSelectedItems] = useState([]);

    const setInitialValue = () => {
        setOpen(false);
    };

    const ref = useOutsideClick(() => setInitialValue());

    useEffect(() => {
        if (!!value.length) {
            setSelectedItems([...value]);
        }
    }, [value]);

    useEffect(() => {
        checkIconFilled();
    }, [selectedItems]);

    const onChangeSelection = (keyName, keyValue) => {
        let filteredData = [...selectedItems];
        const data = filteredData.filter(item => item[keyName] === keyValue)
        if (data?.length > 0) {
            const filterData = filteredData.filter(item => item[keyName] !== keyValue); // Remove the item from the array
            setSelectedItems(filterData);
        } else {
            let updatedFilters = {};
            updatedFilters[keyName] = keyValue;
            setSelectedItems([...selectedItems,  updatedFilters]); // Add the item to the array
        }
    };

    const checkIconFilled = () => {
        if (selectedItems.length) {
            setIconTheme({ theme: "filled" });
        } else {
            setIconTheme({});
        }
    };

    const getState = (data) => {
        const filteredData = selectedItems.filter(item => item[data?.key_name] === data.value);
        return !!filteredData?.length;
    }

    const handleOpenChange = (e, flag) => {
        setOpen(flag);
        onChange(selectedItems)
    };

    const checkboxRender = () => {
        const groups = options.reduce((result, option, index) => {
            if (index % 10 === 0) {
                result.push(options.slice(index, index + 10));
            }
            return result;
        }, []);

        return (
            <DropdownItemBox value={selectedItems} isOpen={open}>
                <Container>
                    {groups.map((group, i) => (
                        <Box>
                            {group.map((label) => (
                                <Box display="flex"  alignItems="center">
                                  <Checkbox
                                      isWhite
                                    checked={getState(label)}
                                    key={label?.key_name}
                                    value={label?.value}
                                    style={{ display: "flex", padding: "10px 0",marginRight:"10px"}}
                                    onChange={() => onChangeSelection(label?.key_name, label?.value)}
                                >
                                </Checkbox>
                                 {label?.label}
                                </Box>

                            ))}
                        </Box>
                    ))}
                </Container>
                <Box display="flex" gridGap="10px">
                    <Buttons minWidth="90px" minHeight="32px" type="outlineColor" text="Annuleren" onClick={() => setOpen(false)} />
                    <Buttons minWidth="90px" minHeight="32px" text="Toepassen" onClick={(e) => handleOpenChange(e,false)} />
                </Box>
            </DropdownItemBox>
        );
    };

    return (
        <Box position="relative" ref={ref}>
            <Box onClick={() => setOpen(!open)} style={{display:"flex",justifyContent:"space-between"}}>
                <Label variant="text8" color="#7E7E7E">{t('Filter op')}</Label> <IconContainer iconName="arrowBottom" p="10px" isOpen={open} />
            </Box>
            {open && checkboxRender()}
        </Box>
    );
};

export default CheckBoxFilterBy;
