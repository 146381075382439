

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Input from "../../../../../components/Input/Input";
import { setOnboardingDetails } from "../../../../Customer/customerAction";
import "../../../../../css/addmember.css"
import SelectBoxCountry from "../../../../Customer/pages/Onboarded/components/SelectBoxCountry"
import Button from "../../../../../components/Buttons/Button";
import { fetchCountries } from "../../../../Mosque/mosqueAction";
import { useTranslation } from "react-i18next";

function AddForm() {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const onboardingDetails = useSelector((state) => state.customerReducer.onboardingDetails);

  const [user, setUserData] = useState({
    contact_name: "",
    contact_email: "",
    code: "",
    contact_phone: "",
    country_code: "+31",
  });

  const handleChangeUser = (keyName, keyValue) => {
    dispatch(setOnboardingDetails(keyName, keyValue));
    let update = { ...user };
    update[keyName] = keyValue;
    setUserData(update);
  };
  useEffect(()=>{
    dispatch(fetchCountries());

  },[])
  return (
    <div className='add_form'>
      <div className="mosque-form">
      <h2>{t('Add Member')}</h2>
        <div className="row">
          <div className="col-lg-6">
            <label>
              {t('Full Name')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_name}
              onChange={(e) => handleChangeUser("contact_name", e.target.value)}
              type="text"
              placeholder="contact_name"
              className="input-box"
              height="66px"
            />
          </div>
          <div className="col-lg-6">
            <label>
              {t('Email Address')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder="Email Address"
              className="input-box"
              height="66px"
            />
          </div>
          <div className="col-lg-6 pt-2">
            <label>
              {t('Contact Number')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={onboardingDetails.contact_phone}
              countryCodes={true}
              setCountryCode={(e) => handleChangeUser("country_code", e)}
              onChange={(e) =>
                handleChangeUser("contact_phone", e.target.value)
              }
              border={user.contact_phone.length ? "1px solid #606734" : null}
              placeholder="785-785-4644"
              className="input-box"
              type="number"
              pattern="[0-9]"
              height="66px"
            />
          </div>
          <div className="col-lg-6 pt-2">
            <label>
             {t('Address')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder="Address"
              className="input-box"
              height="66px"
            />
          </div>
          <div className="col-lg-6 pt-2">
            <label>
            {t('Membership Date')}*<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder="Select Membership Date"
              className="input-box"
              height="66px"
            />
          </div>
          <div className="col-lg-6 pt-2">
            <label>
            {t('Membership')}*<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={onboardingDetails.contact_email}
              onChange={(e) =>
                handleChangeUser("contact_email", e.target.value)
              }
              type="text"
              placeholder="Enter Membership"
              className="input-box"
              height="66px"
            />
          </div>
          <div className="col-lg-6 pt-2">
            <label>
            {t('Payment Method')}*<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <SelectBoxCountry />
          </div>
          <div className="col-lg-6 pt-2">
            <label>
            {t('Plan')}*<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <SelectBoxCountry />
          </div>
        </div>
        <Button
                  bg="#A0AEC0"
                  text={t("Save Details")}
                  borderRadius= "66px"
                  textColor="#fff"
                  width="15%"
                  mt="4"
                  mx="auto"
                  height="60px"
                  // disabled={user.phone_number ? "" : "true"}

               />
        <br />
      </div>
    </div>
  );
}

export default AddForm;
