import React, { useState } from "react";
import { FaPlus, FaTimes } from "react-icons/fa";
import "./PdfUploader.css";
import { truncate } from "lodash";
import { useTranslation } from "react-i18next";

function PdfUploader({ selectedFiles, setSelectedFiles }) {
  const {t} = useTranslation()
  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);
    const pdfFiles = files.filter(
      (file) => file.type === "application/pdf" && file.size <= 1024 * 1024
    );

    const oversizedFiles = files.filter(
      (file) => file.type === "application/pdf" && file.size > 1024 * 1024
    );

    if (oversizedFiles.length > 0) {
      alert(t("Some files were not added as they exceed the 1MB size limit."));
    }

    setSelectedFiles((prevFiles) => [...prevFiles, ...pdfFiles]);
    e.target.value = "";
  };

  const handleRemoveFile = (indexToRemove) => {
    setSelectedFiles((prevFiles) =>
      prevFiles.filter((_, index) => index !== indexToRemove)
    );
  };

  return (
    <div
      className="col-lg-6"

      style={{
        // width: "45%",
        margin: "20px 0",
        paddingRight: "40px",
        background: selectedFiles?.length >= 10 ? "#f1f1f1" : "",
      }}
    >
      <div className="file-input-container input-box ">
        {/* Hidden file input */}
        <input
          type="file"
          accept="application/pdf"
          multiple
          onChange={handleFileChange}
          id="file-input"
          style={{ display: "none" }}
        />

        {/* Custom styled file input */}
        <div
          style={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            width: "100%",
            pointerEvents: selectedFiles?.length >= 10 ? "none" : "",
          }}
        >
          <label htmlFor="file-input" className="file-input-label">
            {t('Choose files')}
          </label>
          <span className="file-chosen">
            {selectedFiles.length > 0
              ? `${selectedFiles.length} file(s) selected`
              : t("No file chosen")}
          </span>
          <button
            onClick={() => document.getElementById("file-input").click()}
            className="add-file-btn"
          >
            <FaPlus />
          </button>
        </div>
      </div>
      <div className="file-list">
        {selectedFiles.map((file, index) => (
          <div key={index} className="file-pill">
            <span className="file-name">
              {truncate(file.name, { length: 10 })}
            </span>
            <FaTimes
              className="remove-icon"
              onClick={() => handleRemoveFile(index)}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default PdfUploader;
