import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; 
import App from './App';
import {Provider} from "react-redux";
import store from './store';
import { I18nextProvider } from 'react-i18next';
import i18n from './services/i18next';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
        <I18nextProvider i18n={i18n}>
      <App />
    </I18nextProvider>
        </Provider>
    </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
