import React from "react";
import Icon from "../../../../../components/Icon/Icon";
import Box from "../../../../../components/Box";
import { useTranslation } from "react-i18next";

const NotSavedPage = ({ setShow, handleEdit }) => {
  const {t} = useTranslation()
  return (
    <>
      <div className="d-flex justify-content-between">
        <div></div>
        <div onClick={setShow}>
          <Icon iconName="blackCross" />
        </div>
      </div>
      <div className="congrats-screen">
        <Box display="flex" justifyContent="center">
          <Icon iconName="notSaved" />
        </Box>
        <h4>{t('Wijzigingen niet opgeslagen')}</h4>
        <p>
        {t('U heeft niet-opgeslagen wijzigingen die verloren gaan als u besluit door te gaan.')}
        </p>

        <div className="d-flex justify-content-around mt-3">
          <button className="prev-bt" onClick={setShow}>
          {t('Niet opslaan')}
          </button>
          <button className="fill-bt" onClick={handleEdit}>
          {t('Wijzigingen opslaan')}
          </button>
        </div>
      </div>
    </>
  );
};

export default NotSavedPage;
