import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const LoginBanner = () => {
  const navigate = useNavigate();
  const handleRedirect = () => {
    navigate("/");
  };
  const {t} = useTranslation()
  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im"  style={{background: "white"}}  onClick={() => handleRedirect()}>
          <img style={{ height: "70px", width: "auto" }} src="/assets/Logo.png"/>
        </div>
        <br/>
        <div className="login_content" style={{marginTop : "-30px"}}>
          <h3>{t('WELKOM')}!!</h3>
          <p>
          {t('Inloggen als beheerder')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoginBanner;
