import React, {useEffect, useState} from "react";
import MosqueLogin from "./MosqueLogin";
import UserLogin from "./UserLogin";
import { useSelector, useDispatch } from "react-redux";
import {
    USER_TYPE_CUSTOMER,
    USER_TYPE_MOSQUE,
} from "../../../../../constants/appEnums";
import { IS_AGENT_EXIST } from "../../../../Mosque/mosqueActionType";
import { IS_CUSTOMER_EXIST, SET_OTP_SENT } from "../../../customerActionType";
import { setOtpSent } from "../../../../Mosque/mosqueAction";
import { useTranslation } from "react-i18next";

function LoginTab() {
    const dispatch = useDispatch();
    const [selectedSegment, setselectedSegment] = useState("Information");
    const {t} = useTranslation()
    const isAuthenticated = useSelector(
        (state) => state.appReducer.isAuthenticated
    );
    const userType = useSelector((state) => state.appReducer.userType);
    const userId = useSelector((state) => state.appReducer.activeUserId);

    const pathName = window.location.search;

    useEffect(() => {
        if (pathName === "?isMosque") {
            setselectedSegment("Information");
        }
    }, [pathName]);

    useEffect(() => {
        if (isAuthenticated && userId) {
            // redirect from route to next route
            if (userType === USER_TYPE_CUSTOMER) {
                window.location.href = `/customer/${userId}/home`;
            }
            if (userType === USER_TYPE_MOSQUE) {
                window.location.href = `/mosque/${userId}/dashboard`;
            }
        }
    }, [isAuthenticated, userId]);

    const setSegment = (selectedSegment) => {
        dispatch({
            type: IS_AGENT_EXIST,
            is_agent_found: false
        });
        dispatch({
            type: IS_CUSTOMER_EXIST,
            is_customer_found: false
        });
        dispatch(setOtpSent(false));
        dispatch({
            type: SET_OTP_SENT,
            status: false
        });
        setselectedSegment(selectedSegment);
    };

    return (
        <div className="login-tab-content" >
            <div>
                <ul className="d-flex login-modal">
                    <li
                        className="information col-12"
                        style={{
                            fontWeight: selectedSegment === "Information" ? "600" : "600",
                            backgroundColor:
                                selectedSegment === "Information" ? "#D0004B" : "#fff",
                            color: selectedSegment === "Information" ? "#fff" : "#D0004B",
                            borderRadius:
                                selectedSegment === "Information" ? "12px 12px 0px 0px" : "",
                        }}
                        onClick={() => setSegment("Information")}
                    >
                        {t('Inloggen')}{" "}
                    </li>
                </ul>
            </div>
            <div className="row login-board-box" >
                {selectedSegment === "Information" ? (
                    <MosqueLogin setSegment={setSegment}/>
                ) : null}
            </div>
        </div>
    );
}

export default LoginTab;
