import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "../../../../components/Buttons";
import Input from "../../../../components/Input";
import UploadFile from "../../../../components/UplodeFile/UploadFile";
import { Helmet } from "react-helmet";
import shortid from "shortid";
import _ from "lodash";
import { useParams, useNavigate } from "react-router-dom";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import styled from "styled-components";
import moment from "moment";
import { getFormattedTime } from "../../../../util/timeUtility";
import ErrorMessage from "../../../../components/ErrorMessage";
import Box from "../../../../components/Box";
import { useTranslation } from "react-i18next";
import { background, width } from "styled-system";
import Icon from "../../../../components/Icon";
import DefaultModal from "../../../../components/DefaultModal/DefaultModal";
import CongratulationModal from "../../../Admin/pages/Members/components/CongratulationModal";
import axios from "axios";
import { showNotification } from "../../../../util/Utility";

const MIN_LENGTH = 50;
const MAX_LENGTH = 500;
const DatePickerBox = styled.div`
  .ant-picker {
    width: 100%;
    height: 54px;
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }
  .ant-picker-focused {
    border: 1px solid #eff0f7;
    box-shadow: 0px 2px 6px #13124212;
    border-radius: 8px;
  }

  .ant-picker .ant-picker-input > input::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #1a1a1a;
  }
`;

const editDonate = false;

function AddCampaign({ campaign = null }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();

  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);
  const [errorData, setErrors] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isShowSuccess, setIsShowSuccess] = useState(false);

  const [donateData, setDonateData] = useState({
    title: "",

    preview_image: "",
    preview_image_alt: "",
    amount: "",

    published_date: getFormattedTime(new Date(), "YYYY-MM-DD"),
    is_active: true,
    is_deleted:false
  });

  // useEffect(() => {
  //     if (editBlog && editBlog._id) {
  //         let newBlog = {...editBlog};
  //         if (_.isArray(newBlog.tags)) {
  //             newBlog.tags = editBlog?.tags?.join(',');
  //         }
  //         newBlog.published_date = moment(new Date(editBlog.published_date)).format("YYYY-MM-DD");
  //         if(selectedSegment === 'update-screen')
  //         setBlogData(newBlog)
  //     }
  // }, [editBlog, selectedSegment])

  useEffect(() => {
    if (campaign) {
      setDonateData({
        title: campaign.title,

        preview_image: campaign.preview_image,
        preview_image_alt: campaign.preview_image_alt,
        amount: campaign.amount,

        published_date: campaign.published_date,
        is_active: campaign.is_active,
      });
    }
  }, [campaign]);

  const handleChangeUser = (keyName, keyValue, value2) => {
    let update = { ...donateData };
    if (isButtonDisabled) setIsButtonDisabled(false);
    setErrors({});
    if (keyName === "publishing_date") {
      update["publishing_date"] = new Date(keyValue?.$d).toISOString();
    } else if (keyName === "preview_image") {
      update["preview_image"] = keyValue;
      update["preview_image_alt"] = value2;
    } else if (keyName === "author_image") {
      update["author_image"] = keyValue;
      update["author_image_alt"] = value2;
    } else {
      update[keyName] = keyValue;
    }
    setDonateData(update);
  };

  const validateBlog = (donateData) => {
    let isValid = true;
    let message = {};
    if (!donateData?.title?.trim()) {
      isValid = false;
      message["title"] = t("Campaign name is required");
    }
    console.log("amoubt ", donateData?.amount, typeof donateData?.amount);

    if (!Number.isFinite(donateData?.amount) && !donateData?.amount?.trim()) {
      isValid = false;
      message["amount"] = t("Amount is required");
    }
    if (!donateData?.preview_image?.trim()) {
      isValid = false;
      message["preview_image"] = t("Image is required");
    }
    return {
      isValid,
      message,
    };
  };

  const monthPickerLocale = [
    "Jan",
    "Feb",
    "Mrt",
    "Apr",
    "Mei",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Okt",
    "Nov",
    "Dec",
  ];

  const onOptionPreviewImage = (details, name) => {
    console.log("preview image -->", details, name);

    handleChangeUser("preview_image", details, name);
  };

  const onOptionAuthorImage = (details, name) => {
    handleChangeUser("author_image", details, name);
  };

  const disablePastDates = (current) => {
    return current && current < moment().startOf("day");
  };
  const saveData = async () => {
    console.log(" donate data ", donateData);
    let check = validateBlog(donateData);
    if (check.isValid) {
      if (campaign) { // basically checking if we have clicked some card...and then edit in this case
        //editing campaign...

        let newCampaign = { ...donateData };

        const trimmedDetails = {};
        // Trim each attribute and assign it to trimmedDetails object
        for (const key in newCampaign) {
          if (typeof newCampaign[key] === "string") {
            trimmedDetails[key] = newCampaign[key].trim();
          } else {
            trimmedDetails[key] = newCampaign[key];
          }
        }
        console.log("edited ", trimmedDetails);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}donation/edit-campaign?campaignId=${campaign._id}`,trimmedDetails)
        console.log(res);
        showNotification('success','Campaign edited successfully')
        window.location.reload()
      } else {
        
        // adding new campaign

        let newDonate = { ...donateData };
        newDonate.entity_id = activeEntity._id;
        // newDonate.organisation_id = activeEntity.organisation_id;

        const trimmedDetails = {};
        
        for (const key in newDonate) {
          if (typeof newDonate[key] === "string") {
            trimmedDetails[key] = newDonate[key].trim();
          } else {
            trimmedDetails[key] = newDonate[key];
          }
        }

        trimmedDetails.url = `${_.kebabCase(
          trimmedDetails.title
        )}-${shortid.generate()}`;
        console.log("validated donate data ", trimmedDetails);
        const res = await axios.post(`${process.env.REACT_APP_API_URL}donation/add-new-campaign`,trimmedDetails)
        console.log(res);
        setIsShowSuccess(true);
      }
    } else {
      setErrors(check.message);
      // showNotification('error','some error occured..')
    }
  };

  const handleDelete = async () => {
    try {
      if (campaign?.recievedDonation > 0) {
        setShowDeleteModal(true);
        return;
      }else{
        handleChangeUser("is_deleted", true)
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(()=>{
   if(donateData.is_deleted) 
      saveData()
  },[donateData.is_deleted])

  return (
    <div className="add_form form_width">
      <Helmet>
        <title>Mosqapp</title>
      </Helmet>
      <div className="mosque-form">
        {/* <h2>Add Member</h2> */}
        <div className="row">
          <div className="col-lg-12">
            <label>
              {t("Campaign Name")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={donateData?.title}
              onChange={(e) => handleChangeUser("title", e.target.value)}
              type="text"
              placeholder={t("Enter Campaign Name")}
              className="input-box"
            />
            {errorData.title ? (
              <ErrorMessage>{t(errorData.title)}</ErrorMessage>
            ) : null}
          </div>

          <div className="col-lg-6 pt-4">
            <label>
              {t("Amount")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Input
              value={donateData.amount}
              onChange={(e) => handleChangeUser("amount", e.target.value)}
              type="number"
              placeholder={t("Enter Amount")}
              className="input-box"
            />
            {errorData.amount ? (
              <ErrorMessage>{t(errorData.amount)}</ErrorMessage>
            ) : null}
          </div>
          <div className="col-lg-6 mt-4">
            <label>
              {t("Image")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <UploadFile
              id="preview"
              onOptionClick={(data, name) => onOptionPreviewImage(data, name)}
              fileName={donateData?.preview_image_alt}
              bg="#fff"
              height="66px"
            />
            {errorData.preview_image ? (
              <ErrorMessage>{t(errorData.preview_image)}</ErrorMessage>
            ) : null}
          </div>
          <div className="col-lg-6 pt-4">
            <label>
              {t("Activating Date")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <DatePickerBox>
              <DatePicker
                defaultValue={dayjs()}
                disabledDate={disablePastDates}
                value={dayjs(donateData?.published_date, "YYYY-MM-DD")}
                onChange={(value) => handleChangeUser("published_date", value)}
                locale={monthPickerLocale}
              />
            </DatePickerBox>
            {errorData.published_date ? (
              <ErrorMessage>{t(errorData.published_date)}</ErrorMessage>
            ) : null}
          </div>
          <div className="col-lg-6 pt-4">
            <label>
              {t("Activate/Inactive")}
              <span style={{ color: "#FE1717" }}>*</span>
            </label>
            <label
              className="switch"
              style={{ display: "block", marginTop: "10px" }}
            >
              <input
                type="checkbox"
                style={{ fontSize: "20px" }}
                checked={donateData.is_active}
                onClick={() =>
                  handleChangeUser("is_active", !donateData.is_active)
                }
              />
              <span className="slider round"></span>
            </label>
          </div>
        </div>
        <Box display="flex" justifyContent="center" mt="20px">
          {campaign && (
            <Box mr="30px">
              <button
                onClick={handleDelete}
                className={
                  campaign?.recievedDonation > 0 ? "prev-bt-disabled" : "prev-bt"
                }
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "10px",
                  width:'max-content',
                  padding:'0 15px'
                }}
              >
                {t('Delete Campaign')}{" "}
                {campaign?.recievedDonation > 0 && (
                  <span>
                    <Icon iconName="infoRed" />
                  </span>
                )}
              </button>
            </Box>
          )}
          <Box>
            <Button
              type={isButtonDisabled ? "disabled" : "primary"}
              style={{width:'max-content', padding:'0 15px'}}
              onClick={() => saveData()}
              text={t("Save Campaign")}
              borderRadius="66px"
              textColor="#fff"
              width="160px"
              mt="5"
              mx="auto"
              height="60px"
            />
          </Box>
        </Box>
        <br />
      </div>
      <DefaultModal
        show={showDeleteModal}
        onHide={() => setShowDeleteModal(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div
          style={{ float: "right" }}
          onClick={() => setShowDeleteModal(false)}
        >
          <Icon
            // style={{zIndex: 2000}}
            iconName="blackCross"
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src="/assets/error-1.svg" alt="error" />
          <p style={{ fontSize: "20px", fontWeight: "700", color: "#1a1a1a" }}>
            {t('Information')}
          </p>
          <p
            style={{
              fontSize: "18px",
              lineHeight: "28px",
              color: "#7E7E7E",
              
              textAlign: "center",
              marginLeft:'30px'
            }}
          >
            {t('Campaigns with donated funds cannot be deleted.')}
           
            {t('You may still inactivate it.')}
          </p>
        </div>
      </DefaultModal>
      <DefaultModal
        show={isShowSuccess}
        onHide={() => setIsShowSuccess(false)}
        className="congrats-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div style={{ float: "right" }} onClick={() => setIsShowSuccess(false)}>
          <Icon
            // style={{zIndex: 2000}}
            iconName="blackCross"
          />
        </div>
        <div className="congrats-screen">
          <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg" />
          <h4>{t("Congratulations!")}</h4>
          <p>
            {t(
              "We have added the new campaign. You can make edits to existing campaign anytime."
            )}
          </p>

          <div className="d-flex justify-content-center mt-3">
            <button onClick={()=>window.location.reload()} className="fill-bt">{t("Add New Campaign")}</button>
            {/* <button className="fill-bt">Next Step</button> */}
          </div>
        </div>{" "}
      </DefaultModal>
    </div>
  );
}

export default AddCampaign;
