import React, { useEffect, useState } from 'react';
import { Progress, Tooltip } from 'antd';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import 'antd/dist/reset.css';
import { showNotification } from '../../../../../util/Utility';
import axios from 'axios';


const donationCollected= 120
const goalAmount = 200
const DonationFormCard = ({campaign}) => {
  const [progressValue, setProgressValue] = useState(124); // Initial value
  const maxValue = 5054;
  const progressPercentage = (campaign?.recievedDonation/campaign?.amount)*100


  const handleProgressChange = (event) => {
    const value = event.target.value;
    setProgressValue(value);
  };

  

  return (
    <div className='donation-form-card'>
      {/* Image */}
      <div style={{ flex: 1 , minWidth:'320px'}}>
        <img
          src={campaign?.preview_image}
          alt="Donation"
          style={{ width: '100%', height: 'auto', borderRadius: '10px' }}
        />
      </div>

      {/* Text and Progress Bar */}
      <div style={{ flex: 1}}>
        {/* Text */}
        <div style={{ marginBottom: '20px' }}>
          <h3 style={{color:'#1a1a1a',fontSize:'20px', fontWeight:600,lineHeight:'34px'}}>{campaign?.title}</h3>
        </div>

        {/* Progress Bar */}
        <div className="progress-bar">
          <div
            className="progress-bar-fill"
            style={{ width: `${progressPercentage}%` }} // Dynamically set width
          >
            
            <Tooltip
            //   key={payment._id}
              title={
                <div>
                 <strong>€{campaign?.recievedDonation.toFixed(2)}</strong>
                </div>
              }
            >
              <div className="progress-pill"></div>
            </Tooltip>
          </div>
          <div
            className="progress-bar-label"
            style={{ left: `${progressPercentage}%` }} // Adjust the label position
          >
            €{donationCollected}
          </div>
        </div>
        <div className="progress-values">
          <span style={{fontSize:'20px', fontWeight:'700',color:'#1a1a1a'}} >€0</span>
          <span style={{fontSize:'20px', fontWeight:'700',color:'#1a1a1a'}}>€{campaign?.amount}</span>
        </div>
      </div>
    </div>
  );
};

export default DonationFormCard;
