import React from 'react'
import '../../../../../css/home.css'
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from 'react-i18next';

function AboutUs() {
  const websiteData = useActiveData();
  const {t} = useTranslation()
  const handleRegisterButton = () => {
    const categoryElement = document.getElementById(`onboarding-state`);
    if (categoryElement && categoryElement.scrollIntoView) {
      categoryElement.scrollIntoView({behavior: "smooth"});
    }
};

  return (
    <div id="about-state">
    <div className='about-us'>
      <div className='col-lg-8 mx-auto'>
      <h2 className='heading-h1'>{t(websiteData.HOME_PAGE_THIRD_SEGMENT_HEADER_TEXT)}</h2>
      <p>
        {t(websiteData.HOME_PAGE_THIRD_SEGMENT_TEXT)}
      </p>
      <button className='common-button' onClick={() => handleRegisterButton()}>{t(websiteData.HOME_PAGE_THIRD_SEGMENT_BTN_TEXT)}</button>
      </div>
      {/* <div className='about-grid'>
        {AboutData && AboutData.map((item) =>(
          <div className='about-box'>
            <div className='img-bg'>
            <img src={item.icon} />
            </div>
        </div>
        )) 

      </div> */}
      </div>
    </div>
  )
}

export default AboutUs
