import React from 'react'
import { useTranslation } from 'react-i18next'

function CongratulationModal() {
  const {t} = useTranslation()
  return (
    
<div className="congrats-screen">
    <img src="https://ik.imagekit.io/rzlzf40okya/elixir/congrats.svg" />
    <h4>{t('Congratulations!')}</h4>
    <p>
    {t('We have saved the details of the member. You can make further edits anytime.')}
    </p>

    <div className="d-flex justify-content-center mt-3">
      <button className="fill-bt">{t('Continue')}</button>
      {/* <button className="fill-bt">Next Step</button> */}
    </div>
  </div>

  )
}

export default CongratulationModal