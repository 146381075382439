import React from 'react'
import { useState, useEffect } from 'react';
import doneImg from '../assets/images/done_dig.svg'
import { useTranslation } from 'react-i18next'
import printDocument from './helpers/printDocument2';
import Banner from './Banner/Banner';
import axios from 'axios';
import { baseUrl } from '../app.config';
import { useParams } from 'react-router-dom';
import Header from "./Header2/Header"

function SuccessPage() {
  const {t} = useTranslation();
  const [loading, setLoading] = useState(false);
  const [currentMosque, setCurrentMosque] = useState(null);
  const [userId, setUserId] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem('digitalFormLangauge'))

  useEffect(()=>{
   setCurrentMosque(JSON.parse(localStorage.getItem('mosqueDigital')))
   setUserId(localStorage.getItem('userIdDigital'))
   setLanguage(localStorage.getItem('digitalFormLangauge'))
   handlePrintDocument();
  },[])

  const handlePrintDocument = async () =>{
     try {
      setLoading(true);
      await printDocument(setLoading,localStorage.getItem('userIdDigital') ,t);
     } catch (error) {
      console.log(error);
      setLoading(false)
     }
  }
 
  return (
    <>
    <Header setLanguage={setLanguage}/>
    <div className="form">
      <Banner mosque={currentMosque} />
    <div className='success'>
        {loading? <p>{t('Please wait the PDF is getting generated ...')}</p>: <p>{t('PDF generated Successfully.')}</p>}
       
        <img src={doneImg} alt="done" className='success-img'/>
        <p className='sub-heading'>{t('Congratulations')}!</p>
        <p className='label' style={{color:'#7e7e7e',width:'70%', textAlign:'center', lineHeight:'28px', marginTop:'-10px', marginBottom:'20px'}}>{t("We will review all the information and if all is okay, our team will message you within 24 - 48 hours")}</p>
        {/* <button onClick={handlePrintDocument} className='pdf-btn' style={{background:loading?"#a0aec0":'#D0004B',color:'white'}}>{t("Download PDF")}</button> */}
    </div>
    </div>
    </>
  )
}

export default SuccessPage