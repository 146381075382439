import React from 'react'
import DonationFormBanner from './components/DonationFormBanner'
import { useTranslation } from 'react-i18next'

function DonationSuccess() {
    const {t} = useTranslation()
  return (
    <div>
        <DonationFormBanner/>
        <div className='donation-form-card' style={{flexDirection:'column',alignItems:'center', maxWidth:'450px',padding:'10px'}}>
           <img src='/assets/stamp1.svg' height={'140px'} width={'140px'}/>
           <p style={{fontWeight:'700', fontSize:'18px', color:'#1a1a1a', textAlign:'center'}}>{t('Payment Sucessful')}</p>
           <p style={{fontSize:'16px', fontWeight:'500', color:'#7E7E7E', textAlign:'center'}}>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</p>
        </div>
    </div>
  )
}

export default DonationSuccess