import React, { useState, useEffect } from "react";
import styled from "styled-components";

import Box from "../../../../../components/Box";
import Label from "../../../../../components/Label";
import Input from "../../../../../components/Input/Input";
import Button from "../../../../../components/Buttons";
import { useDispatch } from "react-redux";
import { ResetPassword, forgetResetLink } from "../../../adminAction";
import isEmail from "validator/lib/isEmail";
import ErrorMessage from "../../../../../components/ErrorMessage";
import { useTranslation } from "react-i18next";

const LoginTabContainer = styled.div`
  position: relative;
  top: -100px;
`;
const LoginModal = styled.div`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 452px;
  margin: 0 auto;
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 20px;
  padding: 34px;
`;

const DetailsBody = styled.div`
  margin-top: 30px;
`;

const BodyContainer = ({ userDetails, setUserDetails }) => {
  const dispatch = useDispatch();
  const [isNext, setIsNext] = useState(false);
  const [errors, setErrors] = useState({});
  const {t} = useTranslation()

  const stepValidation = () => {
    return !!userDetails?.email;
  };

  useEffect(() => {
    if (userDetails) {
      setIsNext(stepValidation());
    }
  }, [userDetails]);

  const handleValidation = () => {
    let isValid = true;
    let error = {};
    if (!userDetails.email || !isEmail(userDetails.email)) {
      isValid = false;
      error["contact_email"] = "Please enter valid email address";
    }
    return {
      isValid,
      error,
    };
  };

  const handleData = (keyName, keyValue) => {
    setErrors({});
    const details = { ...userDetails };
    details[keyName] = keyValue;
    setUserDetails(details);
  };

  const handleChange = () => {
    let validate = handleValidation();
    if (validate.isValid) {
      dispatch(forgetResetLink(userDetails?.email));
    } else {
      setErrors(validate.error);
    }
  };

  return (
    <LoginTabContainer>
      <LoginModal>
        <Label variant="text2" fontWeight={700} justifyContent="center">
          {t('Forget Password')}
        </Label>
        <DetailsBody>
          <Label variant="text5" fontWeight={500} mb="10px">
            {t('Enter Email Id')}
          </Label>
          <Input
            value={userDetails?.email}
            onChange={(e) => handleData("email", e.target.value)}
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder={t('Enter Email Id')}
            className="input-box"
          />
          {errors.contact_email && (
            <ErrorMessage>{t(errors.contact_email)}</ErrorMessage>
          )}
        </DetailsBody>

        <Box display="flex" justifyContent="center" mt="30px">
          <Button
            onClick={() => handleChange()}
            width="40px"
            type={isNext ? "primary" : "disabled"}
          >
            {t('Submit')}
          </Button>
        </Box>
      </LoginModal>
    </LoginTabContainer>
  );
};

export default BodyContainer;
