export const CardData = [
  {
    icon: "PendingDonations",
    title: "Total Donations",
    arrow: "arrowUpGreen",
    heading_key: "current_month_total_revenue",
    last_heading_key: "last_month_total_revenue",
    color: "green",
    paragraph: "this month",
  },
  {
    icon: "TotalDonation",
    arrow: "arrowDownYellow",
    title: "Pending Donations",
    heading_key: "current_month_pending_revenue",
    last_heading_key: "last_month_pending_revenue",
    color: "yellow",
    paragraph: "this month",
  },
];
export const NotificationsData = [
  {
    profile: "bblue",
    profileName: "S",
    name: "Shruti Singh",
    discription: "is added as a new member ",
    days: "1 day ago",
  },
  {
    profile: "gray",
    name: "John",
    profileName: "J",
    discription: "is added as a new member ",
    days: "2 day ago",
  },
  {
    profile: "orange",
    name: "Liam Jones",
    profileName: "L",
    discription: "made payment",
    days: "1 day ago",
  },
  {
    profile: "gray",
    name: "Liam Jones",
    profileName: "G",
    discription: "is added as a new member ",
    days: "3 day ago",
  },
  {
    profile: "orange",
    profileName: "L",
    name: "Liam Jones",
    discription: "made payment",
    days: "1 day ago",
  },
];
export const MonthData = [
  {
    name: "Jan",
    number: 1,
    color: "",
  },
  {
    name: "Feb",
    number: 2,
    color: "",
  },
  {
    name: "Mrt",
    number: 3,
    color: "",
  },
  {
    name: "Apr",
    number: 4,
    color: "",
  },
  {
    name: "Mei",
    number: 5,
    color: "",
  },
  {
    name: "Jun",
    number: 6,
    color: "",
  },
  {
    name: "Jul",
    number: 7,
    color: "",
  },
  {
    name: "Aug",
    number: 8,
    color: "",
  },
  {
    name: "Sep",
    number: 9,
    color: "",
  },
  {
    name: "Okt",
    number: 10,
    color: "",
  },
  {
    name: "Nov",
    number: 11,
    color: "",
  },
  {
    name: "Dec",
    number: 12,
    color: "",
  },
];

export const BlogData = [
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "DONATION",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    profile: "https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg",
    name: "Tracey Wilson",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "DONATION",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    profile: "https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg",
    name: "Tracey Wilson",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "DONATION",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    profile: "https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg",
    name: "Tracey Wilson",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "DONATION",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    profile: "https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg",
    name: "Tracey Wilson",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "DONATION",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    profile: "https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg",
    name: "Tracey Wilson",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "DONATION",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    profile: "https://ik.imagekit.io/rzlzf40okya/elixir/profile_im.svg",
    name: "Tracey Wilson",
    date: "Aug 20, 2022",
  },
];
// export const UserData = [
// {
//   "image":"",
//    "titleone":"",
//    "titleTwo":"",
//    "titleThree":"",
//    "numberone":"",
//    "numbertwo":"",
//    "numberthree":"",

// }
// ]
export const FundData = [
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "Track",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    name: "$148",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "Track",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    name: "$148",
    date: "Aug 20, 2022",
  },
  {
    image: "https://ik.imagekit.io/rzlzf40okya/elixir/blog_bn.svg",
    button: "Track",
    heading:
      "Hemiska lajalig man mot respektive klimatflykting Du kan vara drabbad",
    name: "$148",
    date: "Aug 20, 2022",
  }
]