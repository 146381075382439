import React, { useEffect, useState } from "react";
// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DonateTab from "./components/DonateTabs";
import AddCampaign from "./AddCampaign";
import CampaignCard from "./components/CampaignCard";
import { getFormattedTime } from "../../../../util/timeUtility";
import DefaultModal from "../../../../components/DefaultModal/DefaultModal";
import ProgressBar from "./components/ProgressBar";
import { width } from "styled-system";
import Icon from "../../../../components/Icon";
import { showNotification } from "../../../../util/Utility";
import axios from 'axios'
import Loader2 from "../../../../components/Loader2";
import PaginationV2 from "../../../../components/Pagination/PaginationV2";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const PAST_CAMPAIGNS = "pastCampaigns";
const ADD_CAMPAIGN = "addCampaign";

const PAGE_SIZE = 6;

function DonateListPage() {
  const {t} = useTranslation()
  const [activeTab, setActiveTab] = useState(PAST_CAMPAIGNS);
  const [shouldReorder, setShouldReoder] = useState(false);
  const [clickedCard, setClickedCard] = useState(null);
  const [showTrackModal, setShowTrackModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [totalCount, setTotalCount] = useState(0);
  const activeEntity = useSelector((state) => state.mosqueReducer.activeEntity);

  const [campaigns, setCampaigns] = useState([]);

  // Handle drag end event
  const handleDragEnd = (result) => {
    const { source, destination } = result;

    // Check if dropped outside the list
    if (!destination) {
      return;
    }

    // Reorder the campaigns array
    const reorderedCampaigns = Array.from(campaigns);
    const [movedCampaign] = reorderedCampaigns.splice(source.index, 1);
    reorderedCampaigns.splice(destination.index, 0, movedCampaign);

    setCampaigns(reorderedCampaigns);
  };
  
console.log('clicked card', clickedCard);

  const getContent = () => {
    if (activeTab === PAST_CAMPAIGNS)
      return (
        <div className="donate-cards-container">
          {campaigns.length>0?campaigns.map((campaign) => (
            <CampaignCard
              setClickedCard={setClickedCard}
              data={campaign}
              setShowTrackModal={setShowTrackModal}
            />
          )):<p>{t('Nothing to see here..')}</p>}
        </div>
      );
    else return <AddCampaign campaign={clickedCard} />;
  };
  useEffect(() => {
    const getCampaigns=async ()=>{
      try {
        setIsLoading(true)
        const res = await axios.get(`${process.env.REACT_APP_API_URL}donation/list?entity_id=${activeEntity?._id}&page=${currentPage}&limit=${PAGE_SIZE}`)
        console.log('campaigns',res.data);
        setCampaigns(res.data?.campaigns)
        setTotalCount(res.data?.count)
        setIsLoading(false)
        
      } catch (error) {
        console.log(error);
        setIsLoading(false)
        showNotification('error','some error occured')
      }
    }
    getCampaigns()
  }, [currentPage, activeEntity?._id]);


  useEffect(() => {
    if (clickedCard !== null && !showTrackModal) setActiveTab(ADD_CAMPAIGN);
  }, [clickedCard,showTrackModal]);

  useEffect(() => {
    if (activeTab === PAST_CAMPAIGNS) setClickedCard(null);
  }, [activeTab]);

  return (
    <div className="donateListContainer" style={{ padding: "7px 40px" }}>
      <DonateTab
        activeTab={activeTab}
        shouldReorder={shouldReorder}
        setShouldReoder={setShouldReoder}
        setActiveTab={setActiveTab}
      />
      { isLoading ? <Loader2/>:<main style={{marginBottom:'40px'}}>{getContent()}</main>}
      <DefaultModal
        show={showTrackModal}
        onHide={() => {setShowTrackModal(false); setClickedCard(null)}}
        className="congrats-modal"
        style={{ paddingRight: "0px", width:'600px' }}
        size={'lg'}
        centered={false}
        showHeader={false}
        showFooter={false}
      >
        <div style={{ float: "right" }} onClick={() => {setShowTrackModal(false); setClickedCard(null)}}>
          <Icon
            // style={{zIndex: 2000}}
            iconName="blackCross"
          />
        </div>
        <div style={{padding:'20px'}}>
            <p style={{textAlign:'center', fontSize:'20px', fontWeight:600, color:'#1a1a1a'}}>Hi Lorem Ipsum, your goal is</p>
            <p style={{textAlign:'center', fontSize:'32px', fontWeight:600, marginTop:'-20px', color:'#1a1a1a'}}>{((clickedCard?.recievedDonation/clickedCard?.amount)*100).toFixed(0)}% complete</p>
        <ProgressBar
          donationCollected={clickedCard?.recievedDonation}
          goalAmount={clickedCard?.amount}
          totalDonors={clickedCard?.user_ids.length}
          highestDonation={clickedCard?.higest_donation  || 0}
        />
        </div>
      </DefaultModal>
      {activeTab === PAST_CAMPAIGNS && <PaginationV2
        currentPage={currentPage}
        pageSize={PAGE_SIZE}
        totalCount={totalCount}
        onPageChange={(page)=> setCurrentPage(page)}
      />}
    </div>
  );
}

export default DonateListPage;
