import {
  FETCHING_STATUS,
  SET_MOSQUE_DETAILS,
  FETCHING_SAAS_PLAN_LIST,
  SAAS_PLAN_LIST_FAILURE,
  SAAS_PLAN_LIST_SUCCESS,
  FETCHING_MOSQUE_LIST,
  FETCHING_MOSQUE_LIST_FAILURE,
  FETCHING_MOSQUE_LIST_SUCCESS,
  FETCHING_MEMBER_LIST,
  FETCHING_MEMBER_LIST_FAILURE,
  FETCHING_MEMBER_LIST_SUCCESS,
  ADD_NEW_MOSQUE_SUCCESS,
  ADD_NEW_MOSQUE,
  ADD_NEW_MOSQUE_FAILURE,
  UPDATE_ADDED_NEW_FLAG,
  SET_PLAN_DETAILS,
  ADD_NEW_PLAN,
  ADD_NEW_PLAN_FAILURE,
  ADD_NEW_PLAN_SUCCESS,
  UPDATE_PAGE,
  RESET_LINK_SUCCESS,
  RESET_LINK,
  PASSWORD_CHANGE_SUCCESS,
  PASSWORD_CHANGE,
  DASHBOARD_STATS_DATA,
  DASHBOARD_STATS_DATA_FAILURE,
  DASHBOARD_STATS_DATA_SUCCESS,
  FETCH_ADMIN_PROFILE_DETAIL,
  FETCH_ADMIN_PROFILE_SUCCESS,
  FETCH_ADMIN_PROFILE_FAILED,
  SET_ADMIN_DETAILS,
  UPDATE_ADMIN_SUCCESS,
  UPDATE_MOSQUE_ADMIN_DETAILS,
  UPDATE_MOSQUE_ADMIN_DETAILS_SUCCESS,
  UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE,
  UPDATE_ORG_DETAILS,
  UPDATE_ORG_DETAILS_SUCCESS,
  UPDATE_ORG_DETAILS_FAILURE,
  FETCH_ENTITY_FIN_REVENUE_DETAILS_SUCCESS,
  SET_ENTITY_FIN_REVENUE_DETAILS,
  FETCH_ORG_REVENUE_STATS_DETAILS_SUCCESS,
  FETCH_ORG_SUBS_COUNT_DETAILS_SUCCESS,
  SET_ACTIVE_USER_ID,
  SET_ADMIN,
  UPDATE_MEMBER_INFO,
  UPDATE_MEMBER_INFO_FAILURE,
  UPDATE_MEMBER_INFO_SUCCESS,
  UPDATE_PAGE_FOR_MEMBER_INFO_LIST,
  FETCHING_APPROVAL_LIST,
  FETCHING_APPROVAL_LIST_FAILURE,
  FETCHING_APPROVAL_LIST_SUCCESS,
  MOSQUE_APPROVAL_STATUS,
  LOADING_MEMBER_REMINDER_HISTORY,
  LOADING_MEMBER_PAYMENT_HISTORY,
  LOAD_MEMBER_PAYMENT_HISTORY,
  LOAD_MEMBER_REMINDER_HISTORY, UPDATE_APPROVAL_STATUS, MOSQUE_DECLINE_STATUS, UPDATE_DECLINE_STATUS,
} from "./adminActionType";
import { cloneDeep } from "lodash";

const initialState = {
  isFetching: false,
  isUserDataFetched: false,
  activeUser: {},
  activeUserId: "",
  activeMosque: {},
  activeMosqueId: "",
  mosqueDetails: {
    entity_name: "",
    entity_address: "",
    entity_pincode: "",
    entity_country: "",
    entity_city: "",
    contact_name: "",
    contact_email: "",
    contact_phone: "",
    country_code: "91",
    contact_address: "",
    membership_date: new Date().toISOString(),
    entity_merchant_id:"",
    entity_merchant_key:"",
  },
  isAddedNew: false,
  plans: {
    isFetching: false,
    plan_details: {
      plan_name: "",
      amount: "",
      recurring_type: "",
      payment_day: "",
    },
    plan_list: [],
    plan_count: 0,
  },
  mosque: {
    isFetching: false,
    mosqueList: [],
    mosqueCount: 0,
    currentPage: 1,
    pageSize: 30,
  },
  memberInfo: {
    isFetching: false,
    isFetchingPaymentHistory: false,
    isFetchingReminderHistory: false,
    memberInfoList: [],
    memberInfoCount: 0,
    payments: [],
    memberInfoPayments: {},
    payment_history: [],
    total_payment_count: 0,
    reminder_history: [],
    total_reminder_count: 0,
    currentPage: 1,
    pageSize: 30,
  },
  isResetLinkSend: false,
  isPasswordChange: false,
  dashboardStats: {
    current_month_total_revenue: 0,
    last_month_total_revenue: 0,
    current_month_pending_revenue: 0,
    last_month_pending_revenue: 0,
    current_month_onboarded_count: 0,
    last_month_onboarded_count: 0,
    total_entity_yearly_count: 0,
    total_entity_quarterly_count: 0,
    total_entity_monthly_count: 0,
    total_entity_yearly_revenue: 0,
    total_entity_quarterly_revenue: 0,
    total_entity_monthly_revenue: 0,
    total_entity_yearly_pending_revenue: 0,
    total_entity_quarterly_pending_revenue: 0,
    total_entity_monthly_pending_revenue: 0,
    top_mosques_by_revenue: [],
    top_mosques_by_members: [],
  },
  admin: {},
  entityFinRevenue: [],
  orgSubscriptionCount: {},
  orgRevenueStats: [],
  approvalList: [],
  approvalListCount: 0,
  isApprovedApproval: false,
  isApprovalDecline: false,
};

const AdminReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_LINK:
    case PASSWORD_CHANGE:
    case ADD_NEW_MOSQUE_FAILURE:
    case ADD_NEW_MOSQUE:
    case FETCHING_STATUS:
    case ADD_NEW_PLAN:
    case ADD_NEW_PLAN_FAILURE:
    case DASHBOARD_STATS_DATA:
    case DASHBOARD_STATS_DATA_FAILURE:
    case FETCH_ADMIN_PROFILE_DETAIL:
    case FETCH_ADMIN_PROFILE_FAILED:
    case UPDATE_MOSQUE_ADMIN_DETAILS:
    case UPDATE_MOSQUE_ADMIN_DETAILS_SUCCESS:
    case UPDATE_MOSQUE_ADMIN_DETAILS_FAILURE:
    case UPDATE_ORG_DETAILS:
    case UPDATE_ORG_DETAILS_SUCCESS:
    case UPDATE_ORG_DETAILS_FAILURE:
    case UPDATE_MEMBER_INFO:
    case UPDATE_MEMBER_INFO_FAILURE:
    case FETCHING_APPROVAL_LIST:
    case FETCHING_APPROVAL_LIST_FAILURE:
      return {
        ...state,
        isFetching: action.isFetching,
      };

    case LOADING_MEMBER_PAYMENT_HISTORY:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          isFetchingPaymentHistory: action.isFetching,
        },
      };

    case LOADING_MEMBER_REMINDER_HISTORY:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          isFetchingReminderHistory: action.isFetching,
        },
      };

    case LOAD_MEMBER_PAYMENT_HISTORY:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          payment_history: action.payment_history,
          total_payment_count: action.total_payment_count,
        },
      };

    case LOAD_MEMBER_REMINDER_HISTORY:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          reminder_history: action.reminder_history,
          total_reminder_count: action.total_reminder_count,
        },
      };

    case FETCHING_APPROVAL_LIST_SUCCESS:
      return {
        ...state,
        approvalList: action.list,
        approvalListCount: action.count,
        isFetching: action.isFetching,
      };

    case MOSQUE_APPROVAL_STATUS: {
      const listDetails = cloneDeep(state.approvalList);
      const newList = listDetails.filter(
          (item) => item._id !== action?.approvalId
      );

      return {
        ...state,
        approvalList: newList,
        approvalListCount: newList?.length,
        isApprovedApproval: true,
      };
    }
    case MOSQUE_DECLINE_STATUS: {
      const listDetails = cloneDeep(state.approvalList);
      const newList = listDetails.filter(
          (item) => item._id !== action?.approvalId
      );

      return {
        ...state,
        approvalList: newList,
        approvalListCount: newList?.length,
        isApprovalDecline: true,
      };
    }

    case UPDATE_APPROVAL_STATUS:
      return {
        ...state,
        isApprovedApproval: action.isApprovedApproval
      }

    case UPDATE_DECLINE_STATUS:
      return {
        ...state,
        isApprovalDecline: action.isApprovalDecline
      }

    case SET_ADMIN:
      return {
        ...state,
        admin: action.user,
      };

    case SET_ACTIVE_USER_ID:
      return {
        ...state,
        activeUserId: action.id,
      };

    case PASSWORD_CHANGE_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        isPasswordChange: action.isPasswordChange,
      };

    case RESET_LINK_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        isResetLinkSend: action.isResetLinkSend,
      };

    case UPDATE_PAGE:
      return {
        ...state,
        mosque: {
          ...state.mosque,
          currentPage: action.current,
        },
      };

    case UPDATE_PAGE_FOR_MEMBER_INFO_LIST:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          currentPage: action.current,
        },
      };

    case UPDATE_MEMBER_INFO_SUCCESS:
      const updatedData = state.memberInfo?.memberInfoList.map((data) => {
        let details = { ...data };
        if (data?._id === action?.id) {
          details.is_active = action?.details?.is_active;
        }
        return details;
      });

      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          memberInfoList: updatedData,
        },
      };

    case FETCHING_SAAS_PLAN_LIST:
    case SAAS_PLAN_LIST_FAILURE:
      return {
        ...state,
        plans: {
          ...state.plans,
          isFetching: action.isFetching,
        },
      };

    case ADD_NEW_PLAN_SUCCESS:
      return {
        ...state,
        isAddedNew: action.isAddedNew,
        plans: {
          ...state.plans,
          plan_details: {
            plan_name: "",
            amount: "",
            recurring_type: "",
            payment_day: "",
          },
        },
      };

    case SAAS_PLAN_LIST_SUCCESS:
      return {
        ...state,
        plans: {
          ...state.plans,
          isFetching: action.isFetching,
          plan_list: action.planList,
          plan_count: action.planListCount,
        },
      };

    case UPDATE_ADDED_NEW_FLAG:
      return {
        ...state,
        isAddedNew: action.status,
      };

    case ADD_NEW_MOSQUE_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        isAddedNew: action.isAddedNew,
        mosqueDetails: {
          ...initialState.mosqueDetails, // Reset mosqueDetails to initial state
        },
      };

    /*case SET_MOSQUE_DETAILS: {
      return {
        ...state,
        mosqueDetails: {
          ...state.mosqueDetails,
          entity_name:
            action.keyName === "entity_name"
              ? action.keyValue
              : state.mosqueDetails?.entity_name,
          entity_address:
            action.keyName === "entity_address"
              ? action.keyValue
              : state.mosqueDetails?.entity_address,
          entity_pincode:
            action.keyName === "entity_pincode"
              ? action.keyValue
              : state.mosqueDetails?.entity_pincode,
          entity_country:
            action.keyName === "entity_country"
              ? action.keyValue
              : state.mosqueDetails?.entity_country,
          entity_city:
            action.keyName === "entity_city"
              ? action.keyValue
              : state.mosqueDetails?.entity_city,
          contact_name:
            action.keyName === "contact_name"
              ? action.keyValue
              : state.mosqueDetails?.contact_name,
          contact_email:
            action.keyName === "contact_email"
              ? action.keyValue
              : state.mosqueDetails?.contact_email,
          contact_address:
            action.keyName === "contact_address"
              ? action.keyValue
              : state.mosqueDetails?.contact_address,
          contact_phone:
            action.keyName === "contact_phone"
              ? action.keyValue
              : state.mosqueDetails?.contact_phone,
          country_code:
            action.keyName === "country_code"
              ? action.keyValue
              : state.mosqueDetails?.country_code,
          saas_subscription_plan_id:
            action.keyName === "saas_subscription_plan_id"
              ? action.keyValue
              : state.mosqueDetails?.saas_subscription_plan_id,
          payment_method:
            action.keyName === "payment_method"
              ? action.keyValue
              : state.mosqueDetails?.payment_method,
          membership_date:
            action.keyName === "membership_date"
              ? action.keyValue
              : state.mosqueDetails?.membership_date,
          membership:
            action.keyName === "membership"
              ? action.keyValue
              : state.mosqueDetails?.membership,
        },
      };
    }*/

    case SET_MOSQUE_DETAILS: {
      const updatedMosqueDetails = {
        ...state.mosqueDetails,
        [action.keyName]: action.keyValue,
  };
  
    return {
      ...state,
      mosqueDetails: updatedMosqueDetails,
  };
}

    case FETCHING_MOSQUE_LIST:
    case FETCHING_MOSQUE_LIST_FAILURE:
      return {
        ...state,
        mosque: {
          ...state.mosque,
          isFetching: action.isFetching,
        },
      };

    case FETCHING_MOSQUE_LIST_SUCCESS:
      return {
        ...state,
        mosque: {
          ...state.mosque,
          isFetching: action.isFetching,
          mosqueList: action.list,
          mosqueCount: action.count,
        },
      };

    case FETCHING_MEMBER_LIST:
    case FETCHING_MEMBER_LIST_FAILURE:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          isFetching: action.isFetching,
        },
      };

    case FETCHING_MEMBER_LIST_SUCCESS:
      return {
        ...state,
        memberInfo: {
          ...state.memberInfo,
          isFetching: action.isFetching,
          memberInfoList: action.list,
          memberInfoCount: action.count,
          payments: action.payments,
          memberInfoPayments: action.memberPayments,
        },
      };

    case SET_PLAN_DETAILS: {
      return {
        ...state,
        plans: {
          ...state.plans,
          plan_details: {
            plan_name:
              action.keyName === "plan_name"
                ? action.keyValue
                : state.plans?.plan_details?.plan_name,
            amount:
              action.keyName === "amount"
                ? action.keyValue
                : state.plans?.plan_details?.amount,
            recurring_type:
              action.keyName === "recurring_type"
                ? action.keyValue
                : state.plans?.plan_details?.recurring_type,
            payment_day:
              action.keyName === "payment_day"
                ? action.keyValue
                : state.plans?.plan_details?.payment_day,
          },
        },
      };
    }

    case DASHBOARD_STATS_DATA_SUCCESS:
      return {
        ...state,
        dashboardStats: {
          ...state.dashboardStats,
          current_month_total_revenue:
            action.details?.current_month_total_revenue,
          last_month_total_revenue: action.details?.last_month_total_revenue,
          current_month_pending_revenue:
            action.details?.current_month_pending_revenue,
          last_month_pending_revenue:
            action.details?.last_month_pending_revenue || 0,
          current_month_onboarded_count:
            action.details?.current_month_onboarded_count,
          last_month_onboarded_count:
            action.details?.last_month_onboarded_count,
          total_entity_yearly_count: action.details?.total_entity_yearly_count,
          total_entity_quarterly_count:
            action.details?.total_entity_quarterly_count,
          total_entity_monthly_count:
            action.details?.total_entity_monthly_count,
          total_entity_yearly_revenue:
            action.details?.total_entity_yearly_revenue,
          total_entity_yearly_pending_revenue:
            action.details?.total_entity_yearly_pending_revenue,
          total_entity_quarterly_revenue:
            action.details?.total_entity_quarterly_revenue,
          total_entity_quarterly_pending_revenue:
            action.details?.total_entity_quarterly_pending_revenue,
          total_entity_monthly_revenue:
            action.details?.total_entity_monthly_revenue,
          total_entity_monthly_pending_revenue:
            action.details?.total_entity_monthly_pending_revenue,
          top_mosques_by_revenue: action.details?.top_mosques_by_revenue,
          top_mosques_by_members: action.details?.top_mosques_by_members,
        },
      };

    case FETCH_ADMIN_PROFILE_SUCCESS:
    case UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        isFetching: action.isFetching,
        admin: action.details?.admin,
      };

    case FETCH_ENTITY_FIN_REVENUE_DETAILS_SUCCESS:
      return {
        ...state,
        entityFinRevenue: action.payload,
      };

    case FETCH_ORG_SUBS_COUNT_DETAILS_SUCCESS:
      return {
        ...state,
        orgSubscriptionCount: action.details,
      };

    case FETCH_ORG_REVENUE_STATS_DETAILS_SUCCESS:
      return {
        ...state,
        orgRevenueStats: action.details,
      };

    case SET_ENTITY_FIN_REVENUE_DETAILS:
      return {
        ...state,
        entityFinRevenue: action.val,
      };

    default:
      return state;
  }
};

export default AdminReducer;
