import React from "react";
import {
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  space,
  typography,
} from "styled-system";
import styled, { css } from "styled-components";
import propTypes from "prop-types";

import Icon from "../Icon";
import Loader from "../Loaders/OnlyLoader";
import WhiteLoader from "../Loader2";

const typeToColorMapping = {
  primary: "#ffffff",
  secondary: "#004C73",
  outline: "#222222",
  outlineColor: "#D0004B",
  alert: "#ffffff",
  basic: "#989898",
  light: "#444444",
  lightV2: "#000000",
  disabledPrimary: "#004C73C6",
  disabledAlert: "#fd3a5799",
  disabledSecondary: "#004C73AE",
  disabledOutlineColor: "#A0AEC0",
  disabledWithText: "#989898",
  warning: "#F7BA00",
  blank:"transparent",
  pink: "#D0004B",

};

const disabled = css`
  background:  #A0AEC0;
  color: #000000;
  // border: 1px solid #e4e4e4;
  cursor: not-allowed;
  height: 40px;
`;
const disabledWithText = disabled;

const primary = css`
  background: #D0004B;
  color: #ffffff;
  cursor: pointer;
  // border: 1px solid #bba351;
`;

const secondary = css`
  color: #A0AEC0;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #fff;
`;

const outline = css`
  color: #222222;
  background: #ffffff;
  cursor: pointer;
  // border: 1px solid #222222;
`;

const outlineColor = css`
  color: #D0004B;
  background: #ffffff;
  cursor: pointer;
  border: 1px solid #D0004B;
`;

const alert = css`
  color: #ffffff;
  background-color: #fd3a57;
  cursor: pointer;
  border: 1px solid #fd3a57;
`;

const basic = css`
  color: #ffffff;
  background-color: #989898;
  cursor: pointer;
  border: 1px solid #989898;
`;

const light = css`
  color: #444444;
  background-color: #e9e9e9;
  cursor: pointer;
  border: none;
`;

const lightV2 = css`
  color: #000000;
  background-color: #f2f2f2;
  cursor: pointer;
  border: none;
`;

const disabledPrimary = css`
  background: #A0AEC0;
  color: #ffffff;
  cursor: unset;
  border: none;
`;

const disabledSecondary = css`
  color: #A0AEC0;
  background: #ffffff;
  cursor: unset;
  border: none;
`;

const disabledAlert = css`
  background: #A0AEC0;
  color: #ffffff;
  cursor: unset;
  border: none;
`;
const disabledOutlineColor = css`
  color: #A0AEC0;
  cursor: not-allowed;
  border: 1px solid #A0AEC0;
  background: #FFF;
`;

const warning = css`
  background: #f7ba00;
  color: #444444;
  cursor: pointer;
  border: unset;
`;
const blank = css`
  background: transparent;
  cursor: pointer;
  border: unset;
`;
const pink = css`
  background: rgba(208, 0, 75, 0.08);
  cursor: pointer;
  border: unset;
  color: #D0004B;
`;


const buttonMapping = {
  primary,
  secondary,
  outline,
  outlineColor,
  disabled,
  warning,
  alert,
  basic,
  light,
  lightV2,
  disabledPrimary,
  disabledSecondary,
  disabledAlert,
  disabledOutlineColor,
  disabledWithText,
  blank,
  pink,
};

const StyledButton = styled.button`
  border-radius: 800px;
  font-weight: 600;
  font-size: 16px;
  padding: 8px;
  display: flex;
  align-items: center;
  border: none;

  ${(props) => buttonMapping[props.type]}
  min-width: 120px;
  min-height: 42px;

  ${typography}
  ${space}
  ${border}
  ${layout}
  ${position}
  ${flexbox}
  ${grid}
  ${color}
`;

const Text = styled.p`
  margin: 0;
  padding: 0;
  line-height: 1;
  color: ${(props) => (props.textColor ? props.textColor : "#fff")};
`;

const Button = ({
  children,
  onClick,
  iconName,
  type = "primary",
  iconSize,
  iconRight,
  // TODO : Rather than two different props for loading, use one prop. It should be isLoading
  loader,
  loadingIcon,
  iconProps,
  textColor,
  ...props
}) => {
  const getLeftIcon = () => {
    if (loadingIcon) {
      if (type === "primary" || type === "basic" || type === "alert") {
        return <WhiteLoader mr="8px" />;
      }
      return <Loader mr="8px" />;
    }
    if (iconName) {
      return (
        <Icon
          mr="8px"
          iconSize={iconSize}
          iconName={iconName}
          color={typeToColorMapping[type]}
          {...iconProps}
        />
      );
    }
    return null;
  };

  const renderContent = () => {
    if (loader) {
      if (type === "primary") {
        return <WhiteLoader />;
      }
      return <Loader />;
    }
    return (
      <>
        {getLeftIcon()}
        <Text textColor={typeToColorMapping[type]} >{children || props.text}</Text>
        {iconRight && (
          <Icon
            ml="8px"
            iconSize={iconSize}
            iconName={iconRight}
            color={typeToColorMapping[type]}
          />
        )}
      </>
    );
  };

  return (
    <StyledButton
      disabled={
        type === "disabled" ||
        type === "disabledPrimary" ||
        type === "disabledAlert" ||
        type === "disabledSecondary" ||
        type === "disabledOutlineColor"
      }
      onClick={type !== "disabledWithText" && onClick}
      type={type}
      {...props}
    >
      {renderContent()}
    </StyledButton>
  );
};

Button.propTypes = {
  onClick: propTypes.func,
  iconName: propTypes.string,
  type: propTypes.oneOf([
    "primary",
    "secondary",
    "outline",
    "outlineColor",
    "basic",
    "disabled",
    "warning",
    "alert",
    "light",
    "lightV2",
    "disabledPrimary",
    "disabledSecondary",
    "disabledAlert",
    "disabledOutlineColor",
    "disabledWithText",
    "blank"
  ]),
  iconSize: propTypes.oneOf(["large", "normal", "small", "tiny"]),
  loader: propTypes.bool,
};

Button.defaultProps = {
  onClick: null,
  iconName: null,
  type: "primary",
  loader: false,
  justifyContent: "center",
};
export default Button;
