import React from 'react';
import './FundMeButton.css'; // Importing the CSS file
import Icon from '../../../../../components/Icon';
import { useTranslation } from 'react-i18next';

const FundMeButton = ({isCollapsed=false}) => {
  const {t} = useTranslation()
  return (
    <div className="fund-me-card">
      <p className={isCollapsed?"fund-me-text-hidden":"fund-me-text"}>Consectetur adipiscing elit, sed do eiusmod</p>
      <button className={"fund-me-button"}  >
        <Icon iconName="fundMeIcon"/> 
        {/* You can replace this with an SVG */}
        {" "}
       { isCollapsed?"":t("Fund Me")}
      </button>
    </div>
  );
};

export default FundMeButton;
