import React from "react";
import { useDispatch } from "react-redux";
import { deletePlan } from "../../../adminAction";
import { useTranslation } from "react-i18next";
const DeletePlan = ({ show, setshow, id }) => {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const handleDeletePlan = () => {
    setshow(!show);
    dispatch(deletePlan(id))
  }

  return (
    <div className="congrats-screen">
      <img src="https://ik.imagekit.io/rzlzf40okya/elixir/diseble.svg" />
      <h4>{t('Delete Plan')}</h4>
      <p>
      {t('You will permanently loose this plan’s details and payment information.')}
      </p>

      <div className="d-flex justify-content-around mt-3">
        <button className="prev-bt" onClick={() => setshow(!show)}>{t('Cancel')}</button>
        <button className="fill-bt" onClick={() => handleDeletePlan()}>{t('Delete')}</button>
      </div>
    </div>
  );
}

export default DeletePlan;
