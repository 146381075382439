export const FETCHING_STATUS = "FETCHING_STATUS";

export const SET_AGENT = "SET_AGENT";

export const SET_OTP_SENT = "SET_OTP_SENT";
export const OTP_SENT = "OTP_SENT";
export const OTP_SENT_SUCCESS = "OTP_SENT_SUCCESS";
export const OTP_SENT_FAILURE = "OTP_SENT_FAILURE";
export const IS_AGENT_EXIST = "IS_AGENT_EXIST";

export const VALIDATE_OTP = "VALIDATE_OTP";
export const VALIDATE_OTP_SUCCESS = "VALIDATE_OTP_SUCCESS";
export const VALIDATE_OTP_FAILURE = "VALIDATE_OTP_FAILURE";
export const FETCH_ORGANISATION_ENTITIES = "FETCH_ORGANISATION_ENTITIES";
export const LOAD_AGENT_DASHBOARD_STATS = "LOAD_AGENT_DASHBOARD_STATS";

export const FETCHING_PLAN_LIST = "FETCHING_PLAN_LIST";
export const FETCHING_PLAN_LIST_SUCCESS = "FETCHING_PLAN_LIST_SUCCESS";
export const FETCHING_PLAN_LIST_FAILURE = "FETCHING_PLAN_LIST_FAILURE";

export const FETCHING_ORG_DETAILS = "FETCHING_ORG_DETAILS";
export const FETCHING_ORG_DETAILS_SUCCESS = "FETCHING_ORG_DETAILS_SUCCESS";
export const FETCHING_ORG_DETAILS_FAILURE = "FETCHING_ORG_DETAILS_FAILURE";

export const SET_PLAN_DETAILS = "SET_PLAN_DETAILS";

export const UPDATE_ADDED_NEW_FLAG = "UPDATE_ADDED_NEW_FLAG";

export const ADD_NEW_PLAN = "ADD_NEW_PLAN";
export const ADD_NEW_PLAN_SUCCESS = "ADD_NEW_PLAN_SUCCESS";
export const ADD_NEW_PLAN_FAILURE = "ADD_NEW_PLAN_FAILURE";

export const UPDATE_PLAN = "UPDATE_PLAN";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const UPDATE_PLAN_FAILURE = "UPDATE_PLAN_FAILURE";

export const DELETE_PLAN = "DELETE_PLAN";
export const DELETE_PLAN_SUCCESS = "DELETE_PLAN_SUCCESS";
export const DELETE_PLAN_FAILURE = "DELETE_PLAN_FAILURE";

export const FINANCIALS_STATS_DATA = "FINANCIALS_STATS_DATA";
export const FINANCIALS_STATS_DATA_SUCCESS = "FINANCIALS_STATS_DATA_SUCCESS";
export const FINANCIALS_STATS_DATA_FAILURE = "FINANCIALS_STATS_DATA_FAILURE";

export const FETCH_MEMBER_SUBS_COUNT_DETAILS = "FETCH_MEMBER_SUBS_COUNT_DETAILS";
export const FETCH_MEMBER_SUBS_COUNT_DETAILS_SUCCESS = "FETCH_MEMBER_SUBS_COUNT_DETAILS_SUCCESS";
export const FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE = "FETCH_MEMBER_SUBS_COUNT_DETAILS_FAILURE";

export const FETCH_MEMBER_REVENUE_STATS_DETAILS = "FETCH_MEMBER_REVENUE_STATS_DETAILS";
export const FETCH_MEMBER_REVENUE_STATS_DETAILS_SUCCESS = "FETCH_MEMBER_REVENUE_STATS_DETAILS_SUCCESS";
export const FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE = "FETCH_MEMBER_REVENUE_STATS_DETAILS_FAILURE";

export const FETCHING_MOSQUE_BLOG_LIST_DATA = "FETCHING_MOSQUE_BLOG_LIST_DATA";
export const FETCHING_MOSQUE_BLOG_LIST_DATA_SUCCESS = "FETCHING_MOSQUE_BLOG_LIST_DATA_SUCCESS";
export const FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE = "FETCHING_MOSQUE_BLOG_LIST_DATA_FAILURE";

export const SAVING_EDIT_BLOG = "SAVING_EDIT_BLOG";
export const SAVE_EDIT_BLOG = "SAVE_EDIT_BLOG";
export const ADD_NEW_BLOG = "ADD_NEW_BLOG";
export const FETCHING_BLOG = "FETCHING_BLOG";
export const FETCH_BLOG = "FETCH_BLOG";

export const FETCHING_CUSTOMER_LIST = "FETCHING_CUSTOMER_LIST";
export const FETCHING_CUSTOMER_LIST_SUCCESS = "FETCHING_CUSTOMER_LIST_SUCCESS";
export const FETCHING_CUSTOMER_LIST_FAILURE = "FETCHING_CUSTOMER_LIST_FAILURE";

export const UPDATE_CUSTOMER_INFO = "UPDATE_CUSTOMER_INFO";
export const UPDATE_CUSTOMER_INFO_SUCCESS = "UPDATE_CUSTOMER_INFO_SUCCESS";
export const UPDATE_CUSTOMER_INFO_FAILURE = "UPDATE_CUSTOMER_INFO_FAILURE";
export const SENDING_REMINDER = "SENDING_REMINDER";
export const SEND_REMINDER = "SEND_REMINDER";
export const LOAD_DASHBOARD_MONTH_ON_MONTH_STATS = "LOAD_DASHBOARD_MONTH_ON_MONTH_STATS";
export const SET_MEMBER_DETAILS_VISIBLE = "SET_MEMBER_DETAILS_VISIBLE";
export const LOADING_MEMBER_DETAILS = "LOADING_MEMBER_DETAILS";
export const LOAD_MEMBER_DETAILS = "LOAD_MEMBER_DETAILS";

export const LOAD_MEMBER_PAYMENT_HISTORY = "LOAD_MEMBER_PAYMENT_HISTORY";
export const LOADING_MEMBER_PAYMENT_HISTORY = "LOADING_MEMBER_PAYMENT_HISTORY";
export const LOADING_MEMBER_REMINDER_HISTORY = "LOADING_MEMBER_REMINDER_HISTORY";
export const LOAD_MEMBER_REMINDER_HISTORY = "LOAD_MEMBER_REMINDER_HISTORY";

export const FETCHING_CAMPAIGN_LIST = "FETCHING_CAMPAIGN_LIST";
export const FETCHING_CAMPAIGN_LIST_SUCCESS = "FETCHING_CAMPAIGN_LIST_SUCCESS";
export const FETCHING_CAMPAIGN_LIST_FAILURE = "FETCHING_CAMPAIGN_LIST_FAILURE";

export const ADD_NEW_CAMPAIGN = "ADD_NEW_CAMPAIGN";
export const SAVING_CAMPAIGN = "SAVING_CAMPAIGN";
export const FETCHING_CAMPAIGN = "FETCHING_CAMPAIGN";
export const FETCH_CAMPAIGN = "FETCH_CAMPAIGN";
export const SAVE_CAMPAIGN = "SAVE_CAMPAIGN";

export const UPDATE_MEMBER_INFO_STATUS = "UPDATE_MEMBER_INFO_STATUS";
export const UPDATE_MEMBER_INFO_STATUS_SUCCESS = "UPDATE_MEMBER_INFO_STATUS_SUCCESS";
export const UPDATE_MEMBER_INFO_STATUS_FAILURE = "UPDATE_MEMBER_INFO_STATUS_FAILURE";

export const UPDATE_CUSTOMER = "UPDATE_CUSTOMER";
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS";
export const UPDATE_CUSTOMER_FAILURE = "UPDATE_CUSTOMER_FAILURE";

export const SET_UPDATED_STATUS = "SET_UPDATED_STATUS";

export const FETCHING_AGENT_LIST = "FETCHING_AGENT_LIST";
export const FETCHING_AGENT_LIST_SUCCESS = "FETCHING_AGENT_LIST_SUCCESS";
export const FETCHING_AGENT_LIST_FAILURE = "FETCHING_AGENT_LIST_FAILURE";

export const ADD_AGENT = "ADD_AGENT";
export const ADD_AGENT_SUCCESS = "ADD_AGENT_SUCCESS";
export const ADD_AGENT_FAILURE = "ADD_AGENT_FAILURE";

export const UPDATE_AGENT = "UPDATE_AGENT";
export const UPDATE_AGENT_SUCCESS = "UPDATE_AGENT_SUCCESS";
export const UPDATE_AGENT_FAILURE = "UPDATE_AGENT_FAILURE";

export const FETCHING_APPROVAL_LIST = "FETCHING_APPROVAL_LIST";
export const FETCHING_APPROVAL_LIST_SUCCESS = "FETCHING_APPROVAL_LIST_SUCCESS";
export const FETCHING_APPROVAL_LIST_FAILURE = "FETCHING_APPROVAL_LIST_FAILURE";

export const MEMBER_APPROVAL_STATUS = "MEMBER_APPROVAL_STATUS";
export const UPDATE_APPROVAL_STATUS = "UPDATE_APPROVAL_STATUS";

export const MEMBER_DECLINE_STATUS = "MEMBER_DECLINE_STATUS";
export const UPDATE_DECLINE_STATUS = "UPDATE_DECLINE_STATUS";

export const FETCHING_COUNTRIES = "FETCHING_COUNTRIES";
export const STORE_COUNTIRES = "STORE_COUNTIRES";

export const FETCHING_CITIES = "FETCHING_CITIES";
export const STORE_CITIES = "STORE_CITIES";