import React from 'react'
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from 'react-i18next';

function Apart() {
  const websiteData = useActiveData();
  const {t} = useTranslation()
  return (
    <div className="apart-sec">
      <div className="apart-head">
        <h2 id="mosque-state" className="heading-h1">{t(websiteData.HOME_PAGE_SECOND_SEGMENT_HEADER_TEXT)}</h2>
        <p>
          {t(websiteData.HOME_PAGE_SECOND_SEGMENT_HEADER_SUB_TEXT)}
        </p>
      </div>
      <div className="row d-deskotop">
        <div className="col-lg-7 col-md-7 col-12">
          <img src="assets/MemberInfo.svg" />
        </div>
        <div className="col-lg-5 col-md-5 col-12">
          <div className="apart-right">
            <div className="borderLeft"></div>
            <div className="apart-box">
              <h5>{t(websiteData.HOME_PAGE_SECOND_SEGMENT_FIRST_POINT_HEADER_TEXT)}</h5>
              <p>
                {t(websiteData.HOME_PAGE_SECOND_SEGMENT_FIRST_POINT_TEXT)}
              </p>
            </div>
          </div>
          <div className="apart-right apart-opicty">
            <div className="borderLeft"></div>
            <div className="apart-box">
              <h5>{t(websiteData.HOME_PAGE_SECOND_SEGMENT_SECOND_POINT_HEADER_TEXT)}</h5>
              <p>
                {t(websiteData.HOME_PAGE_SECOND_SEGMENT_SECOND_POINT_TEXT)}
              </p>
            </div>
          </div>
          <div className="apart-right apart-opicty">
            <div className="borderLeft"></div>
            <div className="apart-box">
                <h5>{t(websiteData.HOME_PAGE_SECOND_SEGMENT_THIRD_POINT_HEADER_TEXT)}</h5>
                <p>
                  {t(websiteData.HOME_PAGE_SECOND_SEGMENT_THIRD_POINT_TEXT)}
                </p>
            </div>
          </div>
        </div>
      </div>
      <div className="d-mobile" style={{display: "none"}}>
        
        <div className="col-12">
          <div className="apart-right">
            <div className="borderLeft"></div>
            <div className="apart-box">
              <h5>{t('Tailored for Mosques')}</h5>
              <p>
                Specifically designed to meet the unique needs of mosques,
                ensuring seamless management of donation campaigns,
                contributors, and financial records.
              </p>
            </div>
          </div>
          <div className="col-12">
          <img src="assets/MemberInfo.svg" />
        </div>
          <div className="apart-right apart-opicty">
            <div className="borderLeft"></div>
            <div className="apart-box">
              <h5>{t('Tailored for Mosques')}</h5>
              <p>
                Specifically designed to meet the unique needs of mosques,
                ensuring seamless management of donation campaigns,
                contributors, and financial records.
              </p>
            </div>
          </div>
          <div className="apart-right apart-opicty">
            <div className="borderLeft"></div>
            <div className="apart-box">
              <h5>{t('Tailored for Mosques')}</h5>
              <p>
                Specifically designed to meet the unique needs of mosques,
                ensuring seamless management of donation campaigns,
                contributors, and financial records.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Apart