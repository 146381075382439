import { useEffect, useRef, useState } from "react";
import { callUploadApi } from "../util/apiCaller";
import { showNotification, getFileType } from "../util/Utility";
import { useTranslation } from "react-i18next";

async function readAsDataURL(file) {
  return new Promise((resolve, reject) => {
    const fr = new FileReader();
    fr.onerror = reject;
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsDataURL(file);
  });
}

/**
 *
 * @typedef {Object} FileUploadParams
 * @property {string} accept The file type that is accepted. accept="file_extension|audio/*|video/*|image/*|media_type"
 * @property {"inbox_agent_to_customer" | "quick_replies" | "user_profile" | "user_reported_bugs" | "inbox_qrcode" |
 *   "message_template_sample" | "message_template_media" } [fileCategory] The category of file
 * @property {boolean} [withFBUpload] set it true if you want to save file in FB as well. Default is false
 * @property {number} [sizeLimitInMB] size limit of file in MB
 * @property {string} [sizeExceededError] the error to be shown in toast when size exceeded
 * @property {string} [fileTypeError] the error to be shown if file type given is incorrect
 * @property {(res:Object,file:Object)=>any} [onSuccess] the onSuccess callback
 * @property {(res:Object,file:Object)=>any} [onError] the onError callback
 * @property {Object} [sizeMapping] the sizeMapping as per file type
 */

/**
 *
 * @typedef {Object} FileUploadReturn
 * @property {()=>void} handleUpload Use this function to initiate upload
 * @property {()=>void} handleDragDropUpload Use this function to initiate upload using drag drop
 * @property {Object} apiResponse the response Object from api
 * @property {"idle" | "error" | "loading" | "success"} status of file upload
 * @property {Object} file the file that is being saved to database
 */

/**
 * Use this hook to upload any file
 * @param {FileUploadParams}
 * @returns {FileUploadReturn}
 */
function useFileUpload({
  accept,
  fileCategory = "message_template_sample",
  withFBUpload = false,
  sizeLimitInMB = 5,
  sizeExceededError = "File size should be less then 5MB",
  fileTypeError = "Given file type is incorrect",
  onSuccess,
  onError,
  sizeMapping,
  isNotApiCall = false,
  notApiRes,
}) {
  const [status, setStatus] = useState("idle");
  const [file, setFile] = useState(null);
  const [apiResponse, setApiResponse] = useState({});
  const ref = useRef();
  const {t} = useTranslation()

  const handleFileUpload = async (uploadFiles) => {
    setStatus("idle");
    const fileType = getFileType(uploadFiles[0]);
    if (
      sizeLimitInMB === null &&
      // eslint-disable-next-line no-unsafe-optional-chaining
      uploadFiles[0]?.size > sizeMapping?.[fileType] * 1024 * 1024
    ) {
      if (onError) {
        onError(
          {
            type: "sizeExceededError",
          },
          uploadFiles[0],
          fileType,
        );
      }
      setStatus("error");
    } else if (
      sizeLimitInMB !== null &&
      uploadFiles[0]?.size > sizeLimitInMB * 1024 * 1024
    ) {
      if (sizeExceededError) {
        showNotification("warning", sizeExceededError);
      }
      if (onError) {
        onError(
          {
            type: "sizeExceededError",
            message: `File size should be less then ${sizeLimitInMB}MB`,
          },
          uploadFiles[0],
        );
      }
      setStatus("error");
    } else if (
        uploadFiles[0] &&
        !accept
        ?.split(",")
        .some((oneFileType) =>
          new RegExp(oneFileType.replace("*", ".*")).test(uploadFiles[0]?.type),
        )
      ) {
        if (fileTypeError) {
          showNotification("warning", fileTypeError);
        }
        if (onError) {
          onError(
            {
              type: "fileTypeError",
              message: t("Given file type is incorrect"),
            },
            uploadFiles[0],
          );
        }
        setStatus("error");
    } else if (uploadFiles[0] && !isNotApiCall) {
      try {
        setStatus("loading");
        const formData = new FormData();
        formData.append("file", uploadFiles[0]);

        const endpoint = `media/upload`;
        const res = await callUploadApi(endpoint, "post", formData);
        if (res.data && res.status === "Success") {
          res["media-url"] = res.data?.link;
          setApiResponse({ ...res });
          setFile(uploadFiles[0]);
          setStatus("success");
          if (onSuccess) {
            onSuccess(res, uploadFiles[0]);
          }
        } else {
          showNotification("error", "Something went wrong during media upload");
          setStatus("error");
          if (onError) {
            onError(
              {
                type: "mediaUploadError",
                message: "Something went wrong during media upload",
              },
            );
          }
        }
      } catch (err) {
        showNotification("error", "Something went wrong during media upload");
        setStatus("error");
        if (onError) {
          onError(
            {
              type: "mediaUploadError",
              message: "Something went wrong during media upload",
            },
          );
        }
      }
    } else if (!!isNotApiCall) {
      notApiRes(uploadFiles[0]);
    }
  };

  useEffect(() => {
    ref.current = document.createElement("input");
    ref.current.setAttribute("type", "file");
    ref.current.setAttribute("accept", accept);
    ref.current.addEventListener("change", (e) =>
      handleFileUpload(e.target.files),
    );
    return () => {
      ref.current.remove();
    };
  }, [accept, handleFileUpload]);

  const handleUpload = () => {
    ref.current.click();
  };

  const handleDragDropUpload = (e) => {
    handleFileUpload(e.dataTransfer.files);
  };

  return { handleUpload, handleDragDropUpload, status, file, apiResponse };
}

export default useFileUpload;
