import React from "react";
import { useTranslation } from "react-i18next";
const Banner = ({ link = "#" }) => {
  const handleClick = () => {
    window.location.href = link;
  };
  const {t} = useTranslation()
  return (
    <div className="login_banner">
      <div className="container">
        <div className="logo_im" onClick={() => handleClick()}>
          {t('LOGO')}
        </div>
        <div className="login_content">
          <h3>{t('Welcome!')}</h3>
          <p>
            {t('Use these awesome forms to login or create new <br /> account in your project for free.')}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Banner;
