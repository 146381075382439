import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import Icon from "../../../../components/Icon";
import Label from "../../../../components/Label";
import Input from "../../../../components/Input";
import Button from "../../../../components/Buttons";
import { setAdminDetails, updateAdminDetails, forgetResetLink } from "../../adminAction";
import Box from "../../../../components/Box";
import useMediaUpload from "../../../../customHooks/useMediaUpload";
import ErrorMessage from "../../../../components/ErrorMessage";
import { useTranslation } from "react-i18next";

const AdminProfileBox = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
    0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 40px;
  .changePassword {
    font-weight: 500;
    font-size: 12px;
    color: #d0004b;
    text-align: right;
    margin: 15px 0;
  }
`;
const Image = styled.div`
  height: 142px;
  width: 142px;
  background-color: grey;
  border-radius: 50%;
  position: relative;
  img {
    width: 100%;
    border-radius: 50%;
  }
  .Camera {
    height: 35.5px;
    width: 35.5px;
    border-radius: 50%;
    background: #ffffff;
    position: absolute;
    bottom: -2px;
    right: 16px;
    cursor: pointer;
  }
  .ChangeRemove {
    position: absolute;
    border-radius: 8px;
    box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
      0px 20px 32px rgba(96, 97, 112, 0.24);
    bottom: -100px;
    background: #ffffff;
    width: 145px;
    overflow: hidden;
    div {
      padding: 10px;
      cursor: pointer;
      &:hover {
        background: #d9d9d9;
      }
      &:first-child {
        border-bottom: 1px solid #d9d9d9;
      }
    }
  }
`;
const MyProfile = styled.div`
  font-weight: 700;
  font-size: 30px;
  color: #1a1a1a;
  margin-left: 40px;
`;

const ChangePhoto = styled.div`
  border: 1px solid white;
  text-align: center;
  color: #FFF;
  border-radius: 4px;
  margin: auto;
  width: 120px;
  padding: 10px;
  display: block;
  background-color: #0095ff;
  cursor: pointer;
  
  input[type=file] {
    display: none;
  }
`;

export default function AdminProfile() {
  const dispatch = useDispatch();
  const [isDropdown, setIsDropdown] = useState(false);
  const [isNext, setIsNext] = useState(false);

  const [errors, setErrors] = useState({});

  const [changeProfile, setChangeProfile] = useState({});

  const adminDetails = useSelector((state) => state.adminReducer.admin);
  const {t} = useTranslation()
  useEffect(() => {
    if (adminDetails) {
      setChangeProfile(adminDetails)
    }
  }, [adminDetails]);

  const handleChangeDetails = (keyName, keyValue) => {
    setIsNext(true);
    if( keyName === "contact_email"){
        keyValue = keyValue.toLowerCase();
    }
    let data = { ...changeProfile }
    data[keyName] = keyValue
    setChangeProfile(data);
  };

  const validateDetails = () => {
    let isValid = true;
    let message = {};
    if (!changeProfile.contact_name.trim()) {
      isValid = false;
      message["contact_name"] = "Contact name Cannot Be Empty";
    }
    if (!changeProfile.contact_email.trim()) {
      isValid = false;
      message["contact_email"] = "Contact email Cannot Be Empty";
    }
    return {
      isValid,
      message
    }
  }

  const handleSubmit = () => {
    let check = validateDetails();
    if (check.isValid) {
      dispatch(updateAdminDetails(changeProfile?._id, changeProfile));
    } else {
      setErrors(check.message);
    }
  }

  const removeProfilePic = () => {
    setIsDropdown(false);
    const data = {};
    data.profile_pic = "";
    dispatch(updateAdminDetails(changeProfile?._id, data));
  }

  const mediaType = "IMAGE";

  const { handleUpload, status } = useMediaUpload({
    mediaType,
    onSuccess: (res, fileUploaded) => {
      const data = {};
      data.profile_pic = res?.["media-url"];
      dispatch(updateAdminDetails(changeProfile?._id, data));
      setIsDropdown(false);
    },
  });

  const handleChangePassword = () => {
    if (changeProfile?.contact_email) {
      dispatch(forgetResetLink(changeProfile?.contact_email));
    }
  }

  const handleClickUpload = () => {
    if (changeProfile?.profile_pic) {
      setIsDropdown(!isDropdown)
    } else {
      handleUpload();
    }
  }

  return (
    <AdminProfileBox>
      <div className="d-flex align-items-center">
        <Image>
          {changeProfile?.profile_pic ?
              <img src={changeProfile?.profile_pic} alt="" width="100%" height="100%"/>
              :
              <Box
                  width="100%"
                  height="100%"
                  borderRadius="50%"
                  bg="#D0004B"
              >
                <div className={"text-center"} style={{ paddingTop: "30%", color: "white", fontSize: "40px" }}>{changeProfile && changeProfile.contact_name ? changeProfile?.contact_name[0] : ""}</div>
              </Box>
          }
          {/*<img src={changeProfile?.profile_pic} alt="" width="100%" height="100%" />*/}
          <div
            className="Camera"
          >
            <Icon iconName="Camera" onClick={() => handleClickUpload()} />
          </div>
          {isDropdown && (
            <div className="ChangeRemove" style={{zIndex: 9}}>
              <div onClick={handleUpload}>
                {t('Change Photo')}
              </div>
              <div onClick={() => removeProfilePic()}>{t('Remove Photo')}</div>
            </div>
          )}
        </Image>
        <MyProfile>{t('Mijn profiel')}</MyProfile>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-4">
          <label>
            {t('Naam')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <Input
            value={changeProfile?.contact_name}
            onChange={(e) =>
              handleChangeDetails("contact_name", e.target.value)
            }
            type="text"
            placeholder={t("Voer naam in")}
            className="input-box"
          />
          {errors?.contact_name && <ErrorMessage>{errors.contact_name}</ErrorMessage>}
        </div>
        <div className="col-lg-6 mt-4">
          <label>
          {t('E-mailadres')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <Input
            value={changeProfile?.contact_email}
            onChange={(e) => handleChangeDetails("contact_email", e.target.value)}
            type="text"
            placeholder={t("Voer uw E-mailadres In")}
            className="input-box"
          />
          {errors?.contact_email && <ErrorMessage>{errors.contact_email}</ErrorMessage>}
        </div>
        <div className="col-lg-6 mt-5">
          <label>
          {t('Contactnummer')}<span style={{ color: "#FE1717" }}>*</span>
          </label>
          <Input
            isDisabled={true}
            value={changeProfile?.contact_phone}
            countryCodes={true}
            setCountryCode={(e) => handleChangeDetails("country_code", e)}
            // onChange={(e) => handleChangeUser("phone_number", e.target.value)}
            placeholder="785-785-4644"
            className="input-box"
            type="number"
            pattern="[0-9]"
            height="52px"
            overflow="unset"
          />
        </div>
        <div className="col-lg-6 mt-5">
          <Label variant="text5" fontWeight={500}>
          {t('Wachtwoord')}<span style={{ color: "#FE1717" }}>*</span>
          </Label>
          <Input
            isDisabled
            value={changeProfile?.password}
            // onChange={(e) => setUserDetails({ ...userDetails, password: e.target.value })}
            border="1px solid #EFF0F7"
            boxShadow="0px 2px 6px #13124212"
            borderRadius="8px"
            placeholder={t("Voer Watchwoord In")}
            className="input-box"
            type="text"
            isEyeButton
          />
        </div>
      </div>
      <Box className="changePassword">
        <span className="cursor" onClick={() => handleChangePassword()}>{t('Wachtwoord wijzigen?')}</span>
      </Box>
      <Button
        text={t("Gegevens opslaan")}
        width="15%"
        mt="4"
        mx="auto"
        height="60px"
        type={!isNext ? "disabled" : "primary"}
        onClick={() => handleSubmit()}
      />
    </AdminProfileBox>
  );
}
