import React from 'react'
import { useSelector } from "react-redux";
import Label from "../../../../../components/Label";
import LineChart from "../../../../../components/D3Charts/LineChart";
import styled from "styled-components";
import Box from "../../../../../components/Box";
import { useTranslation } from 'react-i18next';

const FinancialBoxShadow = styled.div`
  background: #ffffff;
  box-shadow: 0px 2px 8px rgba(40, 41, 61, 0.08),
  0px 20px 32px rgba(96, 97, 112, 0.24);
  border-radius: 30px;
  padding: 20px;
`;

const SubscribedUsers = () => {
  const memberSubscriptionCount = useSelector(state => state.mosqueReducer.memberSubscriptionCount);
  const {t} = useTranslation()
  const transformedResultForOrgSubsCount = [
    {
      name: "monthly",
      data: Array(12).fill(0)
    },
    {
      name: "quarterly",
      data: Array(12).fill(0)
    },
    {
      name: "yearly",
      data: Array(12).fill(0)
    }
  ];

  Object.entries(memberSubscriptionCount).forEach(([key, value]) => {
    const [year, month] = key.split("-");
    const {total_user_yearly_count, total_user_monthly_count, total_user_quarterly_count} = value;

    if (total_user_monthly_count) {
      transformedResultForOrgSubsCount[0].data[new Date(`${month} 1, 2000`).getMonth()] = total_user_monthly_count;
    }

    if (total_user_quarterly_count) {
      transformedResultForOrgSubsCount[1].data[new Date(`${month} 1, 2000`).getMonth()] = total_user_quarterly_count;
    }

    if (total_user_yearly_count) {
      transformedResultForOrgSubsCount[2].data[new Date(`${month} 1, 2000`).getMonth()] = total_user_yearly_count;
    }
  });


  return (
    <Box mt="20px">
      <FinancialBoxShadow>
        <Label fontSize="24px" fontWeight="700" justifyContent="center">
          {t('New Subscribed Users')}
        </Label>
        <br></br>
        <Label
            style={{display: "flex", margin: "auto"}}
            fontSize="18px"
            fontWeight="500"
            justifyContent="center"
        >
          <div
              style={{display: "flex", margin: "0px 10px"}}
              justifyContent="center"
          >
            <div
                style={{
                  height: "30px",
                  width: "50px",
                  backgroundColor: "#FFD700",
                  borderRadius: "5px",
                }}
            ></div>
            <p style={{margin: "0px 8px"}}>{t('Monthly Plan')}</p>
          </div>
          <div style={{display: "flex", margin: "0px 10px"}}>
            <div
                style={{
                  height: "30px",
                  width: "50px",
                  backgroundColor: "#DC143C",
                  borderRadius: "5px",
                }}
            ></div>
            <p style={{margin: "0px 8px"}}>{t('Quaterly Plan')}</p>
          </div>
          <div style={{display: "flex", margin: "0px 10px"}}>
            <div
                style={{
                  height: "30px",
                  width: "50px",
                  backgroundColor: "#80CBC4",
                  borderRadius: "5px",
                }}
            ></div>
            <p style={{margin: "0px 8px"}}>{t('Yearly Plan')}</p>
          </div>
        </Label>
        <LineChart width={500}
            dataSet={transformedResultForOrgSubsCount}
            colors={["#FECB17", "#D0004B", "#80CBC4"]}
            categories={[
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "Jul",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ]}
        />
      </FinancialBoxShadow>
    </Box>
  )
}

export default SubscribedUsers
