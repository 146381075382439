import React, { useState, useRef, useEffect } from 'react';
import './CountrySelect.css'; // Import the CSS file
import Icon from "../Icon/Icon";

const CountrySelect = ({ defaultText, handleChangeUser, onboardingDetails, cities }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const dropdownRef = useRef(null);
  
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
    setSearchQuery(''); // Reset search query when toggling dropdown
  };

  const handleCountrySelect = (city) => {
    setSelectedCountry(city);
    handleChangeUser("entity_city", city);
    setIsOpen(false);
    setSearchQuery(''); // Reset search query on selection
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
      setSearchQuery(''); // Reset search query on outside click
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const filteredCities = cities?.filter(city =>
    city.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="dropdown-container" ref={dropdownRef}>
      <div className="input-box dropdown-header" onClick={toggleDropdown}>
        {isOpen ? (
          <input
            type="text"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            // placeholder="Search..."
            className="dropdown-search"
            autoFocus 
          />
        ) : (
          <span>{onboardingDetails?.entity_city || defaultText}</span>
        )}
        <span className="arrow">
          {isOpen ? (
            <Icon iconName="arrowUp" arrowSize="tiny" />
          ) : (
            <Icon iconName="arrowDown" arrowSize="tiny" />
          )}
        </span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {filteredCities?.map((city, i) => (
            <li 
              key={i} 
              onClick={() => handleCountrySelect(city.value)}
              className="dropdown-list-item"
            >
              {city.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default CountrySelect;
