import React from "react";
import '../../../../../css/home.css'
import useActiveData from "../../../../../customHooks/useActiveData";
import { useTranslation } from "react-i18next";

const handleRegisterButton = () => {
    const categoryElement = document.getElementById(`onboarding-state`);
    categoryElement.scrollIntoView({behavior: "smooth"});
};


function Banner() {
    const {t} = useTranslation()
    const websiteData = useActiveData();
    return (
        <div>
            <div className='banner-grid for-desktop'>
                <div className='banner-content'>
                    <h2>{t(websiteData?.HOME_PAGE_FIRST_SEGMENT_HEADER_TEXT)}</h2>
                    <p>{t(websiteData?.HOME_PAGE_FIRST_SEGMENT_HEADER_SUBTEXT)}</p>
                    <button className='common-button'
                            onClick={() => handleRegisterButton()}>{t(websiteData?.HOME_PAGE_FIRST_SEGMENT_BTN_TEXT)}</button>
                </div>
                {/* <div className='banner-grid-img'><img src="./assets/svg/banner.svg" alt=""/></div> */}
            </div>
            <div className='banner-grid-for-mobile for-mobile'>
                <div className='banner-content mt-2'>
                    <h2>{t(websiteData?.HOME_PAGE_FIRST_SEGMENT_HEADER_TEXT)}</h2>
                    <p>{t(websiteData?.HOME_PAGE_FIRST_SEGMENT_HEADER_SUBTEXT)}</p>
                </div>
                {/* <div><img src="./assets/svg/banner.svg" alt=""/></div> */}
                <button className='common-button CB-for-mobile' onClick={() => handleRegisterButton()} style={{
                    width: '135px',
                    margin: '20px auto'
                }}>{t(websiteData?.HOME_PAGE_FIRST_SEGMENT_BTN_TEXT)}</button>
            </div>
        </div>
    )
}

export default Banner
