import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../../css/addmember.css";
import Button from "../../../../../components/Buttons/Button";
import { Select } from "antd";
import { getPaymentRecurringOption } from "../../../../../util/Utility";
import { setPlanDetails, addNewPlan, updateAddedNewFlag, updatePlan } from "../../../adminAction";
import Icon from "../../../../../components/Icon/Icon";
import DefaultModal from "../../../../../components/DefaultModal/DefaultModal";
import { useTranslation } from "react-i18next";
const options = getPaymentRecurringOption();

function EditPlan({ editDetails }) {
  const dispatch = useDispatch();
  const {t} = useTranslation()
  const [isNext, setIsNext] = useState(false);
  const [details, updateDetails] = useState(editDetails);

  const planDetails = useSelector((state) => state.adminReducer.plans?.plan_details);

  const isAddedNew = useSelector((state) => state.adminReducer.isAddedNew);

  const formValidation = () => {
    return !!planDetails?.plan_name &&
      !!planDetails?.amount &&
      !!planDetails?.recurring_type &&
      !!planDetails?.payment_day;
  }

  useEffect(() => {
    if (planDetails) {
      setIsNext(formValidation());
    }
  }, [planDetails])

  const optionPaymentDate = () => {
    let option = [];
    for (let i = 1; i <= 31; i++) {
      const data = {};
      data.label = i;
      data.value = i;
      option.push(data);
    }
    return option;
  }

  const handleChangeUser = (keyName, keyValue) => {
    setIsNext(true);
    const data = { ...details }
    data[keyName] = keyValue;
    updateDetails(data);
  };

  const handleAddPlan = () => {
    dispatch(updatePlan(details?._id, details));
  }

  const handleAddNew = () => {
    dispatch(updateAddedNewFlag(false));
    window.location.href = "admin/planModification"
  };

  return (
    <div className="add_form">
      <div className="mosque-form">
        <div className="row">
          <div className="col-lg-6">
            <label>
              {t('Plan Name')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.plan_name}
              onChange={(e) => handleChangeUser("plan_name", e.target.value)}
              type="text"
              placeholder="Plan name"
              className="input-box"
            />
          </div>
          <div className="col-lg-6 ">
            <label>
              {t('Plan Price')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <input
              value={details?.amount}
              onChange={(e) => handleChangeUser("amount", e.target.value)}
              type="text"
              placeholder="Plan price"
              className="input-box"
            />
          </div>

          <div className="col-lg-6 pt-5">
            <label>
              {t('Billing Frequency')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              value={details?.recurring_type || "Select type"}
              defaultValue="Select type"
              onChange={(value) => handleChangeUser("recurring_type", value)}
              style={{ width: "100%" }}
              options={options}
            />
          </div>
          <div className="col-lg-6 pt-5">
            <label>
              {t('Payment Date')}<span style={{ color: "#FE1717" }}>*</span>
            </label>
            <Select
              size={"middle"}
              value={details?.payment_day || "Select date"}
              defaultValue="Select date"
              onChange={(value) => handleChangeUser("payment_day", value)}
              style={{ width: "100%" }}
              options={optionPaymentDate()}
            />
          </div>
        </div>
        <Button
          type={!isNext ? "disabled" : "primary"}
          text={t("Save Details")}
          width="15%"
          mt="4"
          mx="auto"
          onClick={() => handleAddPlan()}
        />
        <br />
        <DefaultModal
          show={isAddedNew}
          onHide={() => dispatch(updateAddedNewFlag(false))}
          className="add-mosque-modal"
          centered={true}
          showHeader={false}
          size=""
          showFooter={false}
        >
          <div>
            <Icon
              iconName="modalClose"
              iconSize="xs"
              style={{ marginLeft: "auto" }}
              onClick={() => dispatch(updateAddedNewFlag(false))}
            />
            <div className="text-center add-mosque-modal-content">
              <Icon
                iconName="congratulations"
                style={{ justifyContent: "center" }}
              />
              <div>{t('Congratulations!')}</div>
              <div>
                {t('We have added the new plan. You can make edits to <br/> existing plans anytime.')}
              </div>
              <button onClick={() => handleAddNew()}>Edit plan </button>
            </div>
          </div>
        </DefaultModal>
      </div>
    </div>
  );
}

export default EditPlan;
